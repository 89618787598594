import { Badge, message, Card, Col, Statistic, Row, Tooltip, Button, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { getTransactionsList } from './ducks/actions'
import { getSpecialitiesList } from '../Specialties/ducks/actions'
import { UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import AppointmentStatusTag from '../../atoms/AppointmentStatusTag';
import { getProvidersList } from '../Providers/ducks/actions';
import { getPatientsList } from '../Patients/ducks/actions';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';


const setFirstDropDownOption = [
  // {
  //   value:"expired",
  //   label:"Expired"
  // },
  {
    value: "scheduled",
    label: "Scheduled"
  },
  // {
  //   value:"declined",
  //   label:"Declined"
  // },
  // {
  //   value:"pending_approval",
  //   label:"Pending Approval"
  // },
  // {
  //   value:"pending_approval_expired",
  //   label:"Pending Approval Expired"
  // },
  // {
  //   value:"complete_request",
  //   label:"Complete Request"
  // },
  // {
  //   value:"reject",
  //   label:"Reject"
  // },
  {
    value: "cancel",
    label: "Cancel"
  },
  {
    value: "completed",
    label: "Completed"
  }
]

const columns = [
  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    // render: (text, record) => {
    //   return record?.patient?.full_name; // just for decoration
    // },
  },
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient_name',
    render: (text, record) => {
      return record?.patient?.full_name; // just for decoration
    },
  },
  {
    title: 'Provider Name',
    dataIndex: 'provider_name',
    render: (text, record) => {
      return record?.provider?.full_name; // just for decoration
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (text, record) => {
      return `$${record?.amount}`; // just for decoration
    },
  },
  {
    title: 'Transaction Status',
    dataIndex: 'escrowStatus',
    render: (text, record) => {
      return <AppointmentStatusTag status={record?.escrowStatus} isNoteShow={false} />; // just for decoration
    },
  },
  {
    title: 'Booking Status',
    dataIndex: 'booking_status',
    render: (text, record) => {
      return <AppointmentStatusTag status={record?.booking?.status} />; // just for decoration
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) => {
      return moment(record.created_at).format('ll'); // just for decoration
    }
  },
  {
    title: 'Actions',
    dataIndex: 'view',
    key: 'view',
    render: (text, record) => {
      return <Tooltip placement="top" title={'View'}><Link to={`/transaction/view/${record?.id}`} ><EyeOutlined /></Link></Tooltip>
    }
  },
];

const viewAllAppointment = (value) => {
  console.log(value)
}

const Transactions = props => {

  const dispatch = useDispatch();
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const history = useHistory();

  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 10,
    provider_id: history?.location?.state?.provider_id,
    patient_id: null
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

  const transactionsList = useSelector(
    (state: any) => state?.transactions?.transactionList?.data?.data
  );

  const providersList = useSelector(
    (state: any) => state.providers.providerList,
  );

  const patientsList = useSelector(
    (state: any) => state.patients.patientList,
  );

  useEffect(() => {
    setLoading(false)
    setRows([]);
    if (history?.location?.state?.provider_id) {
      setLoading(true)
    }
  }, [history?.location?.state?.provider_id])


  useEffect(() => {
    dispatch(getTransactionsList({
      pageSize: pagination?.pageSize,
      provider_id: history?.location?.state?.provider_id
    }));
    dispatch(getProvidersList({
      pageSize: 100000
    }));
    dispatch(getPatientsList({
      pageSize: 100000
    }));
  }, []);

  useEffect(() => {
    setLoading(true)
    dispatch(getTransactionsList(params))
  }, [params])

  useEffect(() => {
    if (transactionsList?.data?.data) {
      setPagination({
        ...pagination, ...{
          current: transactionsList?.data?.current_page,
          total: transactionsList?.data?.total,
          pageSize: transactionsList?.data?.per_page
        }
      })
      setRows(transactionsList?.data?.data)
      setLoading(false)
    }
  }, [transactionsList])

  useEffect(() => {
    let url = "provider/list";
    const data = Axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired");
        history.push("/dashboard");
      }
    });
  }, []);

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value.current,
      pageSize: value.pageSize,
      order: sorter.order,
      field: sorter.field,
    }
    setParams({ ...params, ...param })
  };

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeFirstDropDown = value => {
    let param = {
      status: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeDateRange = value => {
    let param = {
      page: 1,
      startDate: value?.[0].utc().format('YYYY-MM-DD'),
      endDate: value?.[1].utc().format('YYYY-MM-DD'),
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onDelete = (value) => {
    let param = {
      ids: value
    }
    setLoading(true)
    setOnDeleteSuccess(false)
    // dispatch(providersDelete(param))
    setOnDeleteSuccess(true)
  }

  const onChangeProvider = value => {
    let param = {
      provider_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangePatient = value => {
    let param = {
      patient_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const MoreFilders = props => {
    const providersListOptions = [];
    providersList?.data?.map(function (item) {
      providersListOptions.push({
        label: item?.user?.full_name,
        value: item?.user?.id,
      });
    })

    const patientsListOptions = [];
    patientsList?.data?.map(function (item) {
      patientsListOptions.push({
        label: item?.user?.full_name,
        value: item?.user?.id,
      });
    })

    return <>
      <Col span={4} sm={6}>
        <Select
          style={{ width: '100%' }}
          placeholder={'Select Patient'}
          allowClear
          defaultValue={params?.patient_id}
          options={patientsListOptions}
          onChange={onChangePatient}
        />
      </Col>
      <Col span={4} sm={6}>
        <Select
          style={{ width: '100%' }}
          placeholder={'Select Provider'}
          allowClear
          defaultValue={params?.provider_id}
          options={providersListOptions}
          onChange={onChangeProvider}
        />
      </Col>
    </>
  }

  // useEffect(()=>{

  //   if(providerDelete?.data?.data)
  //   {
  //       dispatch(getProvidersList({
  //         pageSize:pagination?.pageSize
  //       }));
  //       message.success(providerDelete?.message);
  //     }

  // },[providerDelete])



  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'appointments',
      breadcrumbName: 'Appointments',
    }
  ];

  const tagRender = props => {
    return <AppointmentStatusTag tagRender={true} {...props} />
  }

  const allPaymentReleased = () => {
    setLoading(true)
    let url = '/command';
    const data = axios.get(url)
      .then(function (response) {
        console.log(response);
        message.success(response?.data?.message)
        dispatch(dispatch(getTransactionsList({
          pageSize: pagination?.pageSize,
          provider_id: history?.location?.state?.provider_id
        })));
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error?.response);
        //    message.error(error?.response?.data?.message)
      });
  }

  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Transactions | ${pagination?.total}`}
              extra={[
                <Button key="3" type="primary" onClick={allPaymentReleased}>Release All Payment</Button>,
              ]}
            >
              <Row>
                {/* <Statistic
                      title="Total Amount"
                      prefix="$"
                      value={transactionsList?.totalAmount}
                  /> */}
                {/* <Statistic title="Balance" prefix="$" value={3345.08}   style={{
                    margin: '0 32px',
                    }} /> */}
              </Row>
            </PageHeaderSection>
          </Card>
        </Col>
        <Col span={24} className="mt-1">
          <Card
            bordered={false}
            className="noicon-space"
          >
            <SosGrid
              pagination={pagination}
              loading={loading}
              columns={columns}
              onChangeGrid={onChangeGrid}
              dataSource={rows}
              onSearch={(value) => onSearch(value)}
              handelDateRange={onChangeDateRange}
              onDelete={onDelete}
              // setFirstDropDownPlaceHolder={"Select Appointment Status"}
              // setFirstDropDownOption={setFirstDropDownOption}
              // onChangeFirstDropDown={onChangeFirstDropDown}
              setFirstDropDownProps={{ mode: "multiple", tagRender: tagRender }}
              moreFilterProps={<MoreFilders />}
              onDeleteSuccess={onDeleteSuccess}
              rowKey={'id'}
              isBulkDelete={false}
            />
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Transactions;
