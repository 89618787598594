import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getCitiesList = (param={},baseUrl=null) => {
  return async (dispatch) => {
    try {
      let url = null;
      if(!baseUrl){
        url = 'list/cities';
      }
      const data  = await axios.get(baseUrl,{params:param});
      dispatch({
        type: action_types.CITIES_LIST,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
