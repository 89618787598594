import React from 'react';
import { Card, Layout, PageHeader } from "antd";

import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";

const { Content } = Layout;

const DashboardLayout = props => {
  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className='layout-space'>
          {props?.isPageHeader &&
            <Card
              className="h-auto p-0"
              bodyStyle={{ "padding": 0 }}
            >
              <PageHeader
                className="site-page-header"
                onBack={() => null}
                title={props?.pageHeaderTitle}
                subTitle={props?.pageHeaderSubTitle}
              />
            </Card>
          }
          <Content className='main-content'>
            {props.children}
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
