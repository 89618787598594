import axios from "axios";
import { Dispatch } from "redux";
import * as action_types from "./constants";
//import { exampleService } from "./service";


export const getSettingsData = () => {
    return async (dispatch) => {
      try {
        let url = 'setting';
        const data  = await axios.get(url);
        dispatch({
          type: action_types.GET_SETTING,
          data: data
        });
      } catch (e) {
        dispatch({
          type: action_types.GET_SETTING,
          data: e
        });
      }
    };
  };


  export const settingUpdate = (params=null) => {
    return async (dispatch) => {
      try {
        let url = 'setting';
        const data  = await axios.put(url,params);
        dispatch({
          type: action_types.SETTING_UPDATE,
          data: data
        });
      } catch (e) {
        dispatch({
          type: action_types.SETTING_UPDATE,
          data: e?.response?.data
        });
      }
    };
  };
  