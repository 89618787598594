import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, PageHeader, Descriptions, Spin, Switch, Tag, Rate, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserOutlined, FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import DashboardLayout from '../../../molecules/DashboardLayout';
import PageHeaderSection from '../../../molecules/PageHeaderSection';
import { useParams } from "react-router-dom";
import { Content } from 'antd/lib/layout/layout';
import Form from 'antd/lib/form/Form';
import { useForm } from "react-hook-form";
import { DateField, InputField, MaskInputField, SelectField, TextAreaField } from '../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderEdit, providerUpdate, providerStatusUpdate } from '../ducks/actions'
import ProviderEditLeftSideBar from '../../../molecules/ProviderEditLeftSideBar';
import moment from 'moment';
import { getStatesList } from '../../States/ducks/actions';
import { getCitiesList } from '../../Cities/ducks/actions';
import { getSpecialitiesList, resetState } from '../../Specialties/ducks/actions';
import { BASE_URL } from '../../../../configs/constants';
import SosGrid from '../../../molecules/SosGrid';
import AppointmentStatusTag from '../../../atoms/AppointmentStatusTag';
import axios from '../../../../utils/axiosInceptor';
import { useHistory } from "react-router-dom";

const ProviderEdit = props => {
    const params = useParams()
    const dispatch = useDispatch();
    const [providerData, setProviderData] = useState({
        routing_number: null,
        bank_name: null,
        business_name: null,
        speciality: null,
        user: null,
        average_ratings: null,
        bookings: [],
        provider_type: null,
        created_at: null,
        account_number: null,
        user_id: null,
        speciality_id: null,
        totalEarnings: null,
        tax_id: null,
        specialities: [],
        ssn: null
    });
    const [loading, setLoading] = useState(null);
    const [isInvalidProvider, setIsInvalidProvider] = useState(false);
    const [providerType, setProviderType] = useState(null);

    const { control, errors, reset, handleSubmit, setValue, watch, register, setError } = useForm({
        mode: 'onChange',
    });

    let history = useHistory();
    useEffect(() => {
        let url = "provider/list";
        const data = axios.get(url).then(() => { }).catch((e) => {
            if (e.response.data.message == "Unauthenticated") {
                localStorage.removeItem("sos_admin");
                message.error("Your token has been expired", 5);
                history.push("/login");
            }
        });
    }, []);



    const providerEditData = useSelector(
        (state: any) => state.providers.providerEdit,
    );


    const providerUpdateData = useSelector(
        (state: any) => state.providers.providerUpdate,
    );

    const providerStatusUpdateData = useSelector(
        (state: any) => state.providers.providerStatusUpdate,
    );


    const statesList = useSelector(
        (state: any) => state.states.statesList,
    );

    const citiesList = useSelector(
        (state: any) => state.cities.citiesList,
    );

    const specialitiesList = useSelector(
        (state: any) => state.specialities.specialitiesList?.data?.data?.data,
    );

    const allStates = useSelector(
        (state: any) => state,
    );

    useEffect(() => {
        setLoading(true)
        dispatch(getSpecialitiesList({
            pageSize: 1000
        }))
        dispatch(getProviderEdit(params?.id))
        dispatch(getStatesList({
            pageSize: 1000
        }, BASE_URL + 'api/list/states'))

    }, [])

    useEffect(() => {
        if (providerEditData?.data?.data) {
            setProviderData(providerEditData?.data?.data)
            dispatch(getCitiesList({
                pageSize: 1000,
                state_id: providerEditData?.data?.data?.user?.state_id
            }, BASE_URL + 'api/list/cities'))
            setProviderType(providerEditData?.data?.data?.provider_type)
            setLoading(false)
            dispatch(resetState({
                type: 'PROVIDERS_EDIT',
                data: null
            }))
        }
        if (providerEditData?.status == 400) {
            setLoading(false)

            if (providerEditData?.data?.data?.data?.error == 'INVALID_ID') {
                setIsInvalidProvider(true)
            }

            message.error(providerEditData?.data?.message)
        }
    }, [providerEditData])


    const routes = [
        {
            path: '/dashboard',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '/providers',
            breadcrumbName: 'Provider',
        },
        {
            path: 'id',
            breadcrumbName: params.id
        }
    ];

    const getCitiesByStateId = (values) => {

        setValue("user.city_id", null)

        dispatch(getCitiesList({
            pageSize: 1000,
            state_id: values
        }, BASE_URL + 'api/list/cities'))

    }

    const provideStatusChange = (value) => {
        setLoading(false)
        setLoading(true)
        let param = {
            status: value ? 'approved' : 'disapproved'
        }
        dispatch(providerStatusUpdate(params?.id, param));
    }

    useEffect(() => {
        if (providerStatusUpdateData?.data?.message) {
            setLoading(false)
            dispatch(getProviderEdit(params?.id))
            dispatch(resetState({
                type: 'PROVIDERS_STATUS_UPDATE',
                data: null
            }))
            message.success(providerStatusUpdateData?.data?.message)
        }
    }, [providerStatusUpdateData])

    useEffect(() => {
        if (providerUpdateData?.data?.status) {
            message.success(providerUpdateData?.data?.message)
            dispatch(getProviderEdit(params?.id))

            dispatch(resetState({
                type: 'PROVIDERS_UPDATE',
                data: null
            }))

        } else if (providerUpdateData?.data) {
            message.error(providerUpdateData?.data?.message)
        }
    }, [providerUpdateData])

    const onFinish = async (values) => {
        setLoading(false)
        setLoading(true)
        values.email = null;
        values.user.dob = moment(values.user.dob).format('YYYY-MM-DD')
        values.provider.account_number = null
        values.provider.routing_number = null
        dispatch(providerUpdate(params?.id, values));
    }

    let color = ''
    if (providerData?.user?.availability == 'online')
        color = 'green'
    if (providerData?.user?.availability == 'offline')
        color = 'red'
    if (providerData?.user?.availability == 'busy')
        color = 'yellow'

    const userStatus = {
        'pending': 'blue',
        'approved': 'green',
        'disapproved': 'red'
    }

    const providerHandle = (value) => {
        setProviderType(value)
    }

    return <DashboardLayout>

        <Spin spinning={loading} >

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
                <Col span={24}>
                    <Card bordered={false} className="noicon-space" >
                        <PageHeaderSection
                            routes={routes}
                            title={providerData?.user?.full_name}
                            subTitle={<>
                                <AppointmentStatusTag status={providerData?.user?.status} type="admin" />
                                <AppointmentStatusTag status={providerData?.user?.meta?.[0]?.value} />
                            </>}
                            extra={[
                                <span key="4">
                                    <Rate disabled allowHalf value={parseFloat(providerData?.average_ratings)} />
                                    <span className="ant-rate-text">{providerData?.average_ratings && parseFloat(providerData?.average_ratings).toFixed(1)}</span>
                                </span>,
                                // providerType == 'practice' && <Button key="1" type="primary" disabled={isInvalidProvider} >
                                //     <Link to={{
                                //         pathname: `/provider/${providerData?.user_id}/staff/`,
                                //         state: { provider_id: providerData?.user_id }
                                //     }}>
                                //         Staff
                                //     </Link>
                                // </Button>,
                                <Button key="1" type="primary" disabled={isInvalidProvider} >
                                    <Link to={{
                                        pathname: `/appointments`,
                                        state: { provider_id: providerData?.user_id }
                                    }}>
                                        Appointment
                                    </Link>
                                </Button>,
                                <Button key="2" type="primary" disabled={isInvalidProvider}>
                                    <Link to={{
                                        pathname: `/transactions`,
                                        state: { provider_id: providerData?.user_id }
                                    }}>
                                        InCome
                                    </Link>
                                </Button>,
                                <Switch key="3"
                                    disabled={isInvalidProvider}
                                    checkedChildren="Approve"
                                    checked={providerData?.user?.status == 'approved' ? true : false}
                                    unCheckedChildren="Disapprove" style={{ marginLeft: '10px' }} onChange={provideStatusChange} />
                            ]}
                            tags={<Tag color={color}>{providerData?.user?.availability}</Tag>}
                        >
                            <Descriptions size="small" column={4}>
                                <Descriptions.Item label="Registerd At">{moment.utc(providerData?.created_at).format("llll")}</Descriptions.Item>
                                <Descriptions.Item label="Referral Code">{providerData?.user?.refer_code}</Descriptions.Item>
                                <Descriptions.Item label="Referral Person">{providerData?.user?.refer_person}</Descriptions.Item>
                                <Descriptions.Item label="Total Appointment">{providerData?.bookings?.length}</Descriptions.Item>
                                <Descriptions.Item label="Total Income">${providerData?.totalEarnings?.toFixed(2)}</Descriptions.Item>
                                <Descriptions.Item label="Register With" className={"page-header-reg-with"}>
                                    {providerData?.user?.social_type == 'gmail' ? <GoogleOutlined /> : ''}
                                    {providerData?.user?.social_type == 'facebook' ? <FacebookOutlined /> : ''}
                                    {providerData?.user?.social_type}
                                </Descriptions.Item>
                                <Descriptions.Item label="Service House Call"> <AppointmentStatusTag status={providerData?.user?.provider_service_housecall == true ? 'true' : 'false'} /></Descriptions.Item>
                                <Descriptions.Item label="Service Teleconsultant"> <AppointmentStatusTag status={providerData?.user?.provider_service_teleconsultant == true ? 'true' : 'false'} /></Descriptions.Item>
                            </Descriptions>
                        </PageHeaderSection>
                    </Card>
                </Col>
            </Row>

            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>
                    <Col span={6} xs={24} sm={24} md={6} xxl={6}>
                        <ProviderEditLeftSideBar data={providerData} />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={18} xxl={18}>
                        <Card bordered={false} className="noicon-space mt-1">
                            <Row gutter={24}>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="user.first_name"
                                        label="First Name"
                                        control={control}
                                        rules={{
                                            required: "Please enter First name",
                                        }}
                                        iProps={{ placeholder: "First Name", maxLength: "50" }}
                                        initValue=""
                                        validate={errors?.user?.first_name && "error"}
                                        valueGot={providerData?.user?.first_name}
                                        setValue={setValue}
                                        validMessage={errors?.user?.first_name && errors?.user?.first_name?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="user.last_name"
                                        label="Last Name"
                                        control={control}
                                        rules={{
                                            required: "Please enter Last name",
                                        }}
                                        iProps={{ placeholder: "Last Name", maxLength: "50" }}
                                        initValue=""
                                        validate={errors?.user?.last_name && "error"}
                                        valueGot={providerData?.user?.last_name}
                                        setValue={setValue}
                                        validMessage={errors?.user?.last_name && errors?.user?.last_name.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="email"
                                        label="Email"
                                        control={control}
                                        iProps={{ disabled: true, readOnly: true }}
                                        valueGot={providerData?.user?.email}
                                        setValue={setValue}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <DateField
                                        isRequired={true}
                                        fieldname="user.dob"
                                        label="Date of Birth"
                                        control={control}
                                        rules={{
                                            required: "Please enter Date of Birth",
                                        }}
                                        iProps={{ placeholder: "Date of Birth", maxLength: "50" }}
                                        initValue=""
                                        valueGot={moment(providerData?.user?.dob, 'YYYY-MM-DD')}
                                        setValue={setValue}
                                        validate={errors?.user?.dob && "error"}
                                        validMessage={errors?.user?.dob && errors?.user?.dob?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <MaskInputField
                                        isRequired={true}
                                        fieldname="user.phone_no"
                                        label="Phone No"
                                        control={control}
                                        rules={{
                                            required: "Please enter Phone no.",
                                            pattern: {
                                                value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/i,
                                                message: "Please enter valid Phone no."
                                            }
                                        }}
                                        iProps={{
                                            placeholder: "(111) 111-1111",
                                            maxLength: "50",
                                            mask: "(111) 111-1111"
                                        }}
                                        initValue=""
                                        valueGot={providerData?.user?.phone_no}
                                        setValue={setValue}
                                        validate={errors?.user?.phone_no && "error"}
                                        validMessage={errors?.user?.phone_no && errors?.user?.phone_no?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={12} xxl={12}>
                                    <TextAreaField
                                        isRequired={false}
                                        fieldname="address"
                                        label="Address"
                                        control={control}
                                        iProps={{ disabled: true, readOnly: true }}
                                        initValue=""
                                        valueGot={providerData?.user?.address}
                                        setValue={setValue}
                                        validate={errors?.user?.address && "error"}
                                        validMessage={errors?.user?.address && errors?.user?.address?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <SelectField
                                        isRequired={true}
                                        fieldname="user.state_id"
                                        label="State"
                                        control={control}
                                        iProps={{ placeholder: 'Select State' }}
                                        rules={{ required: "Please select State" }}
                                        valueGot={providerData?.user?.state_id}
                                        setValue={setValue}
                                        selectOption={statesList?.data?.data.map((item, index) => {
                                            return { value: item.id, label: item.name }
                                        })}
                                        onChange={getCitiesByStateId}
                                        validate={errors?.user?.state_id && "error"}
                                        validMessage={errors?.user?.state_id && errors?.user?.state_id?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <SelectField
                                        isRequired={true}
                                        fieldname="user.city_id"
                                        label="City"
                                        control={control}
                                        iProps={{ placeholder: 'Select City' }}
                                        rules={{ required: "Please select City" }}
                                        valueGot={providerData?.user?.city_id}
                                        setValue={setValue}
                                        selectOption={citiesList?.data?.data.map((item, index) => {
                                            return { value: item.id, label: item.name }
                                        })}
                                        validate={errors?.user?.city_id && "error"}
                                        validMessage={errors?.user?.city_id && errors?.user?.city_id?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <MaskInputField
                                        isRequired={false}
                                        fieldname="user.zip_code"
                                        label="Zip Code"
                                        control={control}
                                        rules={{
                                            minLength: { value: 5, message: "Please enter valid zip code, 5 digits required" },
                                            maxLength: { value: 5, message: "Please enter valid zip code, 5 digits required" },
                                            pattern: {
                                                value: /[0-9]{5}/,
                                                message: 'Please enter valid zip code.' // JS only: <p>error message</p> TS only support string
                                            }
                                        }}
                                        iProps={{
                                            placeholder: "1111",
                                            maxLength: "5",
                                            mask: "11111"
                                        }}
                                        initValue=""
                                        valueGot={providerData?.user?.zip_code}
                                        setValue={setValue}
                                        validate={errors?.user?.zip_code && "error"}
                                        validMessage={errors?.user?.zip_code && errors?.user?.zip_code?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <SelectField
                                        isRequired={true}
                                        fieldname="provider.speciality_ids"
                                        label="Speciality"
                                        control={control}
                                        iProps={{ placeholder: 'Select Speciality', mode: providerData?.provider_type == 'individual' ? '' : 'multiple' }}
                                        rules={{ required: "Please select Speciality" }}
                                        valueGot={providerData?.specialities?.map(a => a.id)}
                                        setValue={setValue}
                                        selectOption={specialitiesList?.map((item, index) => {
                                            return { value: item.id, label: item.name }
                                        })}
                                        validate={errors?.provider?.speciality_ids && "error"}
                                        validMessage={errors?.provider?.speciality_ids && errors?.provider?.speciality_ids?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <SelectField
                                        isRequired={true}
                                        fieldname="provider.provider_type"
                                        label="Provider Type"
                                        control={control}
                                        rules={{
                                            required: "Please select provider type.",
                                        }}
                                        iProps={{ disabled: true, readOnly: true }}
                                        initValue=""
                                        onChange={providerHandle}
                                        valueGot={providerData?.provider_type}
                                        setValue={setValue}
                                        selectOption={[
                                            { label: 'Individual', value: 'individual' },
                                            { label: 'Practice', value: 'practice' },
                                        ]}
                                        validate={errors?.provider?.provider_type && "error"}
                                        validMessage={errors?.provider?.provider_type && errors?.provider?.provider_type.message}
                                    />
                                </Col>
                                {providerType == 'practice' &&
                                    <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                        <InputField
                                            isRequired={true}
                                            fieldname="provider.business_name"
                                            label="Business Name"
                                            control={control}
                                            rules={{
                                                required: "Please enter Business Name",
                                            }}
                                            iProps={{ placeholder: "Business Name" }}
                                            initValue=""
                                            valueGot={providerData?.business_name}
                                            setValue={setValue}
                                            validate={errors?.provider?.business_name && "error"}
                                            validMessage={errors?.provider?.business_name && errors?.provider?.business_name.message}
                                        />
                                    </Col>
                                }
                                {providerType == 'individual' &&
                                    <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                        <InputField
                                            isRequired={false}
                                            fieldname="user.ssn"
                                            label="SSN"
                                            control={control}
                                            iProps={{ disabled: true, readOnly: true }}
                                            initValue=""
                                            valueGot={providerData?.user?.ssn}
                                            setValue={setValue}
                                            validate={errors?.user?.ssn && "error"}
                                            validMessage={errors?.user?.ssn && errors?.user?.ssn.message}

                                        />
                                    </Col>
                                }
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={false}
                                        fieldname="provider.bank_name"
                                        label="Bank Name"
                                        control={control}
                                        iProps={{ disabled: true, readOnly: true }}
                                        initValue=""
                                        valueGot={providerData?.bank_name}
                                        setValue={setValue}
                                        validate={errors?.provider?.bank_name && "error"}
                                        validMessage={errors?.provider?.bank_name && errors?.provider?.bank_name.message}

                                    />
                                </Col>
                                {providerType == 'practice' &&
                                    <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                        <InputField
                                            isRequired={false}
                                            fieldname="provider.tax_id"
                                            label="Tax Id"
                                            control={control}
                                            iProps={{ disabled: true, readOnly: true }}
                                            initValue=""
                                            valueGot={providerData?.tax_id}
                                            setValue={setValue}
                                            validate={errors?.provider?.tax_id && "error"}
                                            validMessage={errors?.provider?.tax_id && errors?.provider?.tax_id.message}
                                        />
                                    </Col>
                                }
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={false}
                                        fieldname="provider.account_number"
                                        label="Account Number"
                                        control={control}
                                        // rules={{
                                        //     required: "Please enter Account Number",
                                        //     minLength: { value: 12, message: "Please enter valid account number, 12 digits required" },
                                        //     maxLength: { value: 12, message: "Please enter valid account number, 12 digits required" },
                                        //     pattern: {
                                        //         value: /[0-9]{12}/,
                                        //         message: 'Please enter valid account number.' // JS only: <p>error message</p> TS only support string
                                        //     }
                                        // }}
                                        iProps={{ disabled: true, readOnly: true }}
                                        initValue=""
                                        valueGot={providerData?.account_number}
                                        setValue={setValue}
                                        validate={errors?.provider?.account_number && "error"}
                                        validMessage={errors?.provider?.account_number && errors?.provider?.account_number.message}

                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        // isRequired={true}
                                        fieldname="provider.routing_number"
                                        label="Routing Number"
                                        control={control}
                                        // rules={{
                                        //     required: "Please enter Routing Number",
                                        //     minLength: { value: 9, message: "Please enter valid routing number, 9 digits required" },
                                        //     maxLength: { value: 9, message: "Please enter valid routing number, 9 digits required" },
                                        //     pattern: {
                                        //         value: /[0-9]{9}/,
                                        //         message: 'Please enter valid routing number.' // JS only: <p>error message</p> TS only support string
                                        //     }
                                        // }}
                                        iProps={{ disabled: true, readOnly: true }}
                                        initValue=""
                                        valueGot={providerData?.routing_number}
                                        setValue={setValue}
                                        validate={errors?.provider?.routing_number && "error"}
                                        validMessage={errors?.provider?.routing_number && errors?.provider?.routing_number.message}
                                    />
                                </Col>
                                <Col span={24} xs={24} sm={24} md={24} xxl={24}>

                                    {!isInvalidProvider && <Button type="primary" htmlType="submit">Update</Button>}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24} className="mt-1">
                        {window.location.hostname == 'localhost' && <SosGrid
                            loading={loading}
                            columns={[
                                {
                                    title: 'Ip Address',
                                    dataIndex: 'ip'
                                },
                                {
                                    title: 'Country',
                                    dataIndex: 'country'
                                },
                                {
                                    title: 'Region',
                                    dataIndex: 'region'
                                },
                                {
                                    title: 'City',
                                    dataIndex: 'city'
                                },
                                {
                                    title: 'Browser',
                                    dataIndex: 'browser'
                                },
                                {
                                    title: 'Device Type',
                                    dataIndex: 'device_type'
                                },
                                {
                                    title: 'Device',
                                    dataIndex: 'device'
                                },
                                {
                                    title: 'Platform',
                                    dataIndex: 'platform'
                                },
                                {
                                    title: 'Current',
                                    dataIndex: 'is_current',
                                    render: (text, record) => {
                                        return <AppointmentStatusTag status={record.is_current ? 'online' : 'offline'} />
                                    }
                                },
                                {
                                    title: 'Created At',
                                    dataIndex: 'created_at',
                                    render: (text, record) => {
                                        return moment.utc(record.created_at).format('llll'); // just for decoration
                                    }
                                }
                            ]}
                            dataSource={providerData?.user?.logins}
                        />}
                    </Col>
                </Row>
            </Form>
        </Spin>

    </DashboardLayout >
}

export default ProviderEdit;