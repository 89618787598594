import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated,getCurrentUser } from '../utils/helper';

const  PrivateRoute = (props) =>{
    const { component: Component, ...rest } = props;

    const render = props => {
        if (!getCurrentUser()) {
            return <Redirect to='/login' />;
        }
        return <Component {...props} />;
    };

    return <Route {...rest} render={render} />;
}

export default PrivateRoute;