import * as action_types from "./constants";

const dashboard = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.DASHBOARD: 
        return {
          ...state,
          dashboardData: data,
        };
      default:
        return state;
    }

};

export default dashboard;
