import { Row, Col, Card, List, Typography, Button, Descriptions, Space, Rate, Spin, message } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../molecules/DashboardLayout';
import PageHeaderSection from '../../../molecules/PageHeaderSection';
import { getTransactionDetails } from '../ducks/actions'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link, useParams } from "react-router-dom";
import AppointmentStatusTag from '../../../atoms/AppointmentStatusTag';
import { resetState } from '../../Specialties/ducks/actions';
import axios from 'axios';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { useHistory } from "react-router-dom";

const { confirm } = Modal;

const { Title, Text } = Typography;

const routes = [
    {
        path: '/dashboard',
        breadcrumbName: 'Dashboard',
    },
    {
        path: '/transactions',
        breadcrumbName: 'Transactions',
    },
    {
        path: 'transactions-view',
        breadcrumbName: 'Details',
    }
];

const TransactionDetails = props => {

    const dispatch = useDispatch();
    const params = useParams()
    const [loading, setLoading] = useState(null);
    const [details, setDetails] = useState(null);

    const transactionDetails = useSelector(
        (state: any) => state?.transactions?.transactionDetails,
    );


    useEffect(() => {
        setLoading(true)
        dispatch(getTransactionDetails({
            id: params?.id
        }));
    }, []);

    const showConfirm = (type) => {
        confirm({
            title: 'Are you sure want to ' + type + ' payment?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                paymentRelease(type);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const paymentRelease = (type) => {


        setLoading(true)
        let url = '/transactions/payment-release';
        const value = {
            transaction_id: details?.transaction_id,
            type: type
        }
        const data = axios.put(url, value)
            .then(function (response) {
                console.log(response);
                message.success(response?.data?.message)
                dispatch(getTransactionDetails({
                    id: params?.id
                }));
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error?.response);
                message.error(error?.response?.data?.message)
            });
    }

    useEffect(() => {
        if (transactionDetails?.data?.data) {
            setLoading(false)
            setDetails(transactionDetails?.data?.data)
            dispatch(resetState({
                type: 'TRANSACTION_DETAILS',
                data: null
            }))
        }
    }, [transactionDetails])

    const history = useHistory();
    useEffect(() => {
        let url = "provider/list";
        const data = Axios.get(url).then(() => { }).catch((e) => {
            if (e.response.data.message == "Unauthenticated") {
                localStorage.removeItem("sos_admin");
                message.error("Your token has been expired");
                history.push("/dashboard");
            }
        });
    }, []);

    return <DashboardLayout>
        <Spin spinning={loading} delay={500}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
                <Col span={24}>
                    <Card
                        bordered={false}
                        className="noicon-space"
                    >
                        <PageHeaderSection
                            routes={routes}
                            subTitle={<AppointmentStatusTag status={details?.status} />}
                            title={`Transaction Details`}
                            extra={[
                                <Button key="1" type="primary">
                                    <Link to={{
                                        pathname: '/appointment/view/' + details?.booking_id + '/ffff3227-04d9-4a90-bda6-079c3892c8c5',
                                    }}>
                                        Go To Booking
                                    </Link>
                                </Button>,
                            ]}
                        >
                            <Descriptions size="small" column={4}>
                                <Descriptions.Item label="Created At">{moment(details?.created_at).format("LLLL")}</Descriptions.Item>
                            </Descriptions>
                        </PageHeaderSection>
                    </Card>
                </Col>
                <Col span={6} className="mt-1">
                    <Card
                        title="Provider"
                        className="appointment-detail-avatar"
                        cover={<img alt={details?.provider?.full_name} src={details?.provider?.avatar?.medium} />}
                        actions={[
                            <Button type="primary" className="no-border" block>
                                <Link to={`/provider/edit/${details?.provider?.provider?.id}`}> View Profile </Link>
                            </Button>,
                        ]}
                    >
                        <Meta title="Full Name" description={details?.provider?.full_name} />
                    </Card>
                </Col>
                <Col span={6} className="mt-1">
                    <Card
                        title="Patient"
                        className="appointment-detail-avatar"
                        cover={<img alt={details?.patient?.full_name} src={details?.patient?.avatar?.medium} />}
                        actions={[
                            <Button type="primary" className="no-border" block>
                                <Link to={`/patient/edit/${details?.patient?.patient?.id}`}> View Profile </Link>
                            </Button>,
                        ]}
                    >
                        <Meta title="Full Name" description={details?.patient?.full_name} />
                    </Card>
                </Col>
                <Col span={12} className="mt-1">
                    <Card>
                        <Title level={4}>Transaction Time: {moment(details?.transaction_at).format('LLLL')} </Title>
                        <Space direction="vertical">
                            <Text><strong>Amount</strong>: {details?.amount && '$' + details?.amount}</Text>
                            <Text><strong>Service Fee Amount</strong>: {details?.serviceFeeAmount && '$' + details?.serviceFeeAmount}</Text>
                            <Text><strong>Total Amount</strong>: {details?.amount && '$' + (details?.amount - details?.serviceFeeAmount).toFixed(2)}</Text>
                            <Text><strong>Transaction Status</strong>: <AppointmentStatusTag status={details?.transaction_status} /></Text>
                            <Text><strong>Payment Hold Status</strong>: <AppointmentStatusTag isNoteShow onClick={showConfirm} status={details?.escrowStatus} /></Text>
                            <Text><strong>Booking Status</strong>: <AppointmentStatusTag status={details?.booking_status} /></Text>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Spin>
    </DashboardLayout>;
}

export default TransactionDetails;