import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getPatientsList = (param = {}) => {
  return async (dispatch) => {
    try {
      let url = "patient/list";
      const data = await axios.get(url, { params: param });
      dispatch({
        type: action_types.PATIENTS_LIST,
        data: data.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PATIENTS_LIST,
        data: e,
      });
    }
  };
};

export const patientsDelete = (param) => {
  return async (dispatch) => {
    try {
      let url = "patient/delete";
      const data = await axios.post(url, param);
      dispatch({
        type: action_types.PATIENTS_DELETE,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PATIENTS_DELETE,
        data: e.response.data,
      });
    }
  };
};

export const getPatientEdit = (id) => {
  return async (dispatch) => {
    try {
      let url = "patient/edit/" + id;
      const data = await axios.get(url);
      dispatch({
        type: action_types.PATIENTS_EDIT,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PATIENTS_EDIT,
        data: e?.data,
      });
    }
  };
};

export const patientUpdate = (id, params = null) => {
  return async (dispatch) => {
    try {
      let url = "patient/update/" + id;
      const data = await axios.put(url, params);
      dispatch({
        type: action_types.PATIENTS_UPDATE,
        data: data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PATIENTS_UPDATE,
        data: e,
      });
    }
  };
};
