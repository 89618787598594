import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, Typography, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { getStatesList } from '../States/ducks/actions'
import { UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Popup } from '../../atoms/Popup';
import { Tag, Divider } from 'antd';
import { BASE_URL } from '../../../configs/constants';
import Axios from 'axios';
import { useHistory } from "react-router-dom";



const States = (props) => {

  const dispatch = useDispatch();
  const [rows, setRows] = useState([])
  const [specialitiesData, setStatesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const [visible, setVisible] = useState(null)
  const [trigger, setTrigger] = useState(null)
  const [popupData, setPopupData] = useState({})
  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 5,
  })
  const [onClose, setOnClose] = useState(false);
  const { Title } = Typography;



  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0
  })

  const providerDelete = useSelector(
    (state: any) => state.providers.providersDelete,
  );

  const statesList = useSelector(
    (state: any) => state.states.statesList,
  );

  useEffect(() => {
    setLoading(true)
    dispatch(getStatesList({
      pageSize: pagination?.pageSize
    }, BASE_URL + 'api/list/states'))
  }, []);

  useEffect(() => {
    if (statesList?.data) {
      setPagination({
        ...pagination, ...{
          current: statesList?.current_page,
          total: statesList?.total,
          pageSize: statesList?.per_page
        }
      })
      setRows(statesList?.data)
      setLoading(false)
    }
  }, [statesList])

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value?.current,
      pageSize: value?.pageSize,
      order: sorter?.order,
      field: sorter?.field,
    }
    setParams({ ...params, ...param })
  };


  const actionEdit = (text, params) => {
    return (
      <>
        <Space size={16}>
          <Button type="link"
            onClick={() => handleUpdate(params)}
            className="p-0 ag-success">
            <EditOutlined />
          </Button>
          {/* <Button type="link"   danger className="p-0">
            <DeleteOutlined style={{ color: '#FB7171' }}    onClick={() => deleteTimeSlots(params)} />
          </Button> */}
        </Space>
      </>
    )
  }
  const handleUpdate = (params) => {
    setPopupData(params)
    console.log(params)
    setVisible(true);
    setTrigger(0);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return <Tag color={record?.status == 'active' ? 'green' : 'red'}>{record?.status == 'active' ? 'Active' : 'Inactive'}</Tag>
      }

    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        return moment.utc(record.created_at).format('LLLL'); // just for decoration
      }
    },
    {
      headerName: "Actions",
      dataIndex: "actions",
      render: actionEdit,
    },
  ];


  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Update a Speciality
        </Title>
      ),
      content: (
        <>asd</>
        // <UpdateSpeciality
        //  setVisible={setVisible}
        //  onClose={onClose} 
        //  setOnClose={setOnClose}
        //  popupData={popupData}
        // />
      ),
      width: 650,
    }
  ];


  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger]?.title,
    content: popup[trigger]?.content,
    width: popup[trigger]?.width,
    onCancel: () => onCancel(),
  };


  const onCancel = () => {
    setOnClose(true)
    setVisible(false)
  }

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setLoading(true)
      dispatch(getStatesList())
    }
  }, [params])

  let history = useHistory();
  useEffect(() => {
    let url = "provider/list";
    const data = Axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired");
        history.push("/dashboard");
      }
    });
  }, []);

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'specilities',
      breadcrumbName: 'Specilities',
    }
  ];

  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Specilities | ${pagination?.total}`}
            />
          </Card>
        </Col>
        <Col span={24} className="mt-1">
          <Card
            bordered={false}
            className="noicon-space"
          >
            <SosGrid
              pagination={pagination}
              loading={loading}
              onChangeGrid={onChangeGrid}
              columns={columns}
              dataSource={rows}
              onSearch={(value) => onSearch(value)}
              rowKey={'id'}
            />
          </Card>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </DashboardLayout>
  );
};

export default States;
