import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getInfluencerList = (param = {}) => {
  return async (dispatch) => {
    try {
      let url = "influencer/list";
      const data = await axios.get(url, { params: param });
      dispatch({
        type: action_types.INFLUENCER_LIST,
        data: data.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.INFLUENCER_LIST,
        data: e,
      });
    }
  };
};

export const influencerDelete = (param) => {
  return async (dispatch) => {
    try {
      let url = "influencer/delete";
      const data = await axios.post(url, param);
      dispatch({
        type: action_types.INFLUENCER_DELETE,
        data: data?.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getInfluencerEdit = (id) => {
  return async (dispatch) => {
    try {
      let url = "influencer/edit/" + id;
      const data = await axios.get(url);
      dispatch({
        type: action_types.INFLUENCER_EDIT,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.INFLUENCER_EDIT,
        data: e?.response,
      });
    }
  };
};

export const influencerUpdate = (id, params = null) => {
  return async (dispatch) => {
    try {
      let url = "influencer/update/" + id;
      const data = await axios.put(url, params);
      dispatch({
        type: action_types.INFLUENCER_UPDATE,
        data: data,
      });
    } catch (e) {
      dispatch({
        type: action_types.INFLUENCER_UPDATE,
        data: e,
      });
    }
  };
};

export const influencerStatusUpdate = (id, params = null) => {
  return async (dispatch) => {
    try {
      let url = "influencer/status/update/" + id;
      const data = await axios.put(url, params);
      dispatch({
        type: action_types.INFLUENCER_STATUS_UPDATE,
        data: data,
      });
    } catch (e) {
      dispatch({
        type: action_types.INFLUENCER_STATUS_UPDATE,
        data: e,
      });
    }
  };
};
