import { Row, Col, Card, List, Typography, Button, Descriptions, Space, Rate, Spin, Drawer, message } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../molecules/DashboardLayout';
import PageHeaderSection from '../../../molecules/PageHeaderSection';
import { bookingAddReview, getAppointmentDetails } from '../ducks/actions'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link, useParams } from "react-router-dom";
import AppointmentStatusTag from '../../../atoms/AppointmentStatusTag';
import Avatar from 'antd/lib/avatar/avatar';
import { BASE_URL } from '../../../../configs/constants';
import { useHistory } from "react-router-dom";
import axios from '../../../../utils/axiosInceptor';

const { Title, Text } = Typography;

const routes = [
  {
    path: '/dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/appointments',
    breadcrumbName: 'Appointments',
  },
  {
    path: 'appointment-details',
    breadcrumbName: 'Details',
  }
];

const data = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];

const AppointmentDetails = props => {

  const dispatch = useDispatch();
  const params = useParams()
  const [loading, setLoading] = useState(null);
  const [details, setDetails] = useState(null);
  const [viewChatStatus, setViewChatStatus] = useState(false);
  let history = useHistory();

  const appointemenDetails = useSelector(
    (state: any) => state.appointments.appointementDetails,
  );

  const appointmentAddReview = useSelector(
    (state: any) => state.appointments.appointmentAddReview,
  );

  useEffect(() => {
    let url = "provider/list";
    const data = axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired", 5);
        history.push("/login");
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true)
    dispatch(getAppointmentDetails({
      id: params?.id
    }));
  }, [appointmentAddReview]);

  useEffect(() => {
    setDetails({});
    if (appointemenDetails?.data?.data) {
      setLoading(false)
      setDetails(appointemenDetails?.data?.data)
    }
  }, [appointemenDetails])



  const onClose = () => {
    setViewChatStatus(false);
  };

  const onViewChat = () => {
    setViewChatStatus(true);
  }

  const handleReviewChange = (data: any) => {
    setLoading(true)
    dispatch(bookingAddReview({
      booking_id: params?.id,
      patient_id: details?.patient?.patient?.id,
      provider_id: details?.provider?.provider?.id,
      rating: data
    }));
  }

  return <DashboardLayout>
    <Spin spinning={loading} delay={500}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              subTitle={<AppointmentStatusTag status={details?.status} />}
              title={`Appointments Details`}
              extra={[
                <Button key="2" type="primary" onClick={onViewChat}>Chat history</Button>,
              ]}
            >
              <Descriptions size="small" column={4}>
                <Descriptions.Item label="Created At">{moment(details?.created_at).format("LLLL")}</Descriptions.Item>
              </Descriptions>
            </PageHeaderSection>
          </Card>
        </Col>
        <Col span={6} className="mt-1">
          <Card
            title="Provider"
            className="appointment-detail-avatar"
            cover={<img alt={details?.provider?.full_name} src={details?.provider?.avatar?.medium} />}
            actions={[
              <Button type="primary" className="no-border" block>
                <Link to={`/provider/edit/${details?.provider?.provider?.id}`}> View Profile </Link>
              </Button>,
            ]}
          >
            <Meta title="Full Name" description={details?.provider?.full_name} />
          </Card>
        </Col>
        <Col span={6} className="mt-1">
          <Card
            title="Patient"
            className="appointment-detail-avatar"
            cover={<img alt={details?.patient?.full_name} src={details?.patient?.avatar?.medium} />}
            actions={[
              <Button type="primary" className="no-border" block>
                <Link to={`/patient/edit/${details?.patient?.patient?.id}`}> View Profile </Link>
              </Button>,
            ]}
          >
            <Meta title="Full Name" description={details?.patient?.full_name} />
          </Card>
        </Col>
        <Col span={12} className="mt-1">
          <Card>
            <Title level={4}>Appointment Time: {moment(details?.provider_time).format('LLLL')} </Title>
            <Title level={5}>Fee: ${details?.provider_price}</Title>
            <Space direction="vertical">
              {details?.status == 'declined' && <Text><strong>Reason:</strong> {details?.reason}</Text>}
              <Text><strong>Provider Comment</strong>: {details?.provider_comment}</Text>
              <Text><strong>Speciality</strong>: {details?.speciality?.name}</Text>
              <Text><strong>Symptom</strong>: {details?.symptoms?.name}</Text>
              <Text><strong>Address:</strong> {details?.address}</Text>
              <Text><strong>Other Instruction:</strong> {details?.other_instruction}</Text>
              <Text><strong>Request Type:</strong> {details?.request_type}</Text>
              <Text><strong>Review:</strong> {details?.ratings?.review}</Text>
              <span>
                <Rate allowHalf value={details?.ratings?.rating} disabled={details?.ratings?.rating && details?.ratings?.rating} onChange={handleReviewChange} />
                {console.log('details?.ratings', details)}
                {details &&
                  details?.ratings?.rating !== undefined ?
                  <span className="ant-rate-text">
                    {parseFloat(details?.ratings?.rating)?.toFixed(1)}
                  </span>
                  : ''
                }
              </span>
            </Space>
          </Card>
        </Col>
      </Row>
    </Spin>
    <ChatDrawer viewChatStatus={viewChatStatus} onClose={onClose} id={params?.id} />
  </DashboardLayout>;
}

export default AppointmentDetails;


const ChatDrawer = props => {

  const { viewChatStatus, onClose, id } = props;
  const [loading, setLoading] = useState(null);
  const [chatList, setChatList] = useState([]);

  const getChatList = async () => {
    setLoading(true)
    try {
      let url = 'appointments/view';
      const data = await axios.get(BASE_URL + 'api/chat', { params: { booking_id: id } }).then(function (response) {
        setLoading(false)
        setChatList(response?.data?.data?.data);
      });
    } catch (e) {
      setLoading(false)
      console.log("Err", e);
    }
  }

  useEffect(() => {
    getChatList()
  }, [id])

  return <Drawer
    title="View Chat"
    width={640}
    placement="right"
    closable={false}
    visible={viewChatStatus}
    onClose={onClose}
    className={'appointment-chat'}
  >
    <Spin spinning={loading} delay={500}>
      <List
        itemLayout="horizontal"
        dataSource={chatList}
        size={'small'}
        renderItem={item => (



          <>{
            item?.data?.[0]?.sender_id == item?.patient_id ?
              <List.Item className="patient-chat-item">
                <Text className="user_type"><span>Patient</span></Text>
                <List.Item.Meta
                  avatar={<Avatar src={item?.patient?.avatar?.thumb} />}
                  title={item?.patient?.full_name + ' | ' + moment(item?.created_at).format('llll')}
                  description={item?.data?.[0]?.message}
                />
              </List.Item> :
              <List.Item className="provider-chat-item">
                <Text className="user_type"><span>Provider</span></Text>
                <List.Item.Meta
                  avatar={<Avatar src={item?.provider?.avatar?.thumb} />}
                  title={moment(item?.created_at).format('llll') + ' | ' + item?.provider?.full_name}
                  description={item?.data?.[0]?.message}
                />
              </List.Item>
          }</>


        )}
      />
    </Spin>
  </Drawer>

}