import * as action_types from "./constants";

const STAFF = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.STAFF_LIST: 
        return {
          ...state,
          staffList: data,
        };
      case action_types.STAFF_DELETE: 
        return {
          ...state,
          staffDelete: data,
        };
      case action_types.STAFF_EDIT: 
        return {
          ...state,
          staffEdit: data,
        };
      case action_types.STAFF_UPDATE: 
        return {
          ...state,
          staffUpdate: data,
        };
      case action_types.STAFF_STATUS_UPDATE: 
        return {
          ...state,
          staffStatusUpdate: data,
        };
      default:
        return state;
    }

};

export default STAFF;
