import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin, Switch } from "antd";
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import {  InputField,SelectField,SwitchField } from "../../atoms/FormElement";
import axios from "../../../utils/axiosInceptor";
import { useDispatch, useSelector } from 'react-redux';
import {getSymptomsList} from '../Symptoms/ducks/actions'

import moment from 'moment';
import { Popup } from "../../atoms/Popup";

const { Paragraph } = Typography;
const defaultValues: any = {id:null,name:null,status:null};

const AddSymptom = (props) => {

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, handleSubmit,reset, setValue,getValues,setError } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const [start,setStart] = useState('');
  const [formData,setFormData] = useState({id:null,name:null,status:null});
  const [indexes, setIndexes] = useState([1]);
  const [counter, setCounter] = useState(2);
  const [newRow, setNewRow] = useState(false);



  useEffect(()=>{
    reset({ defaultValues });
  },[props?.onClose])


  const resetForm=()=>{
    props?.setVisible(false)
    props?.setOnClose(false)
  }

  const onFinish = async (value) => {
    
    setSubmited(true);
    setLoader(true);
    try {
      let url = `/symptoms/create`
      await axios.post(url,value);
      setLoader(false); 
      setSubmited(false);
      message.success("Symptoms added successfully");
      dispatch(getSymptomsList({
        pageSize:5
      }))
      reset({ defaultValues });
      props.setVisible(false)
    }
    catch(e){
      setLoader(false);
      setisError(true);
      const { response } = e;
      if(response?.data?.errors?.name?.[0]){
        setError('name', {
          type: "server",
          message: response?.data?.errors?.name?.[0],
        });
      }
      setErrorMessage(response?.data?.message);
    }
   
  };


  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label={"Name"}
                control={control}
                rules={{
                  required:"Please enter symptom name." ,
                }}
                iProps={{ placeholder:  "Symptom Name" }}
                setValue={setValue}
                valueGot= {formData.name}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>

            <Col span={12}>
            <SelectField
                isRequired={true}
                fieldname="status"
                label="Status"
                control={control}
                iProps={{ placeholder: 'Select Status'}}
                rules={{ required: "Please select status" }}
                valueGot={formData?.status}
                setValue={setValue}
                selectOption={[
                  {label:'Active',value:'active'},
                  {label:'InActive',value:'inactive'},
                ]}
                validate={errors?.status && "error"}
                validMessage={errors?.status && errors?.status?.message}
              />
            </Col>
           
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={()=>{
                  reset()
                  props.setVisible(false)
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddSymptom;
