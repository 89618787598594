import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useEffect } from 'react';
import { Button, Card, Col, message, Switch , Spin } from 'antd';
import Form from 'antd/lib/form/Form';
import { useForm } from "react-hook-form";
import { InputField, SwitchField } from '../../../atoms/FormElement';
import { useSelector } from 'react-redux';


const IsProduction = props => {

  const [loading,setLoading] = useState(true);
  const [dataField,setDataField] = useState({
    is_voip_production:0
  });

  const settingData = useSelector(
    (state:any) => state.settings.settingsData?.data?.data?.data,
  );

  const { control, errors,setValue ,reset, handleSubmit,watch,register ,setError} = useForm({
    mode: 'onChange',
  });


  useEffect(()=>{
    if(!!settingData){
      setLoading(false)
    }
  },[settingData])

  const onFinish = (value)=>{
    setLoading(true)
    let url = '/setting';

    value.key = 'is_voip_production',
    value.value = value.is_voip_production

    const data  =  axios.put(url,value)
    .then(function (response) {
      message.success(response?.data?.message)
      setLoading(false)
    })
    .catch(function (error) {
        setLoading(false)
      console.log(error?.response);
      message.error(error?.response?.data?.message)
    });
  }

    return (
        <Card
        bordered={false}
        className="noicon-space"
        title="Voip Production"
      >
        
            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Spin spinning={loading} >
                    <SwitchField
                      isRequired={true}
                      fieldname="is_voip_production"
                      label="Production"
                      control={control}
                      iProps={{ 
                        checkedChildren: 'True',
                        unCheckedChildren:'False'
                      }}
                      initValue=""
                      validate={errors?.is_voip_production && "error"}
                      valueGot={settingData?.is_voip_production}
                      setValue={setValue}
                      validMessage={errors?.is_voip_production && errors?.is_voip_production?.message}
                    />
                    <Button type="primary" htmlType="submit">Update</Button>
                </Spin>
            </Form>
        </Card>
    );
};

export default IsProduction;
