import React from 'react';
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Breadcrumb, PageHeader } from 'antd';

const PageHeaderSection = props => {

      const breadcrumb = (props,routes:any) => {
        return (
          <Breadcrumb>
            {routes.map((item, index) => (
              <Breadcrumb.Item key={index}>
                {index + 1 === routes.length ? (
                  item.breadcrumbName
                ) : (
                  <Link to={item.path}>{item.breadcrumbName}</Link>
                )}
              </Breadcrumb.Item>
            ))
            }
          </Breadcrumb>
        )
      }

      return (
        <PageHeader
            className="site-page-header"
            {...props}
            breadcrumbRender={()=>breadcrumb(props,props?.routes)}
          />
        );
};

export default PageHeaderSection;
