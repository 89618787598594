import React, { useState } from 'react';
import { Row, Col, Card, Button, Spin, Select, message, Input } from 'antd';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import Form from 'antd/lib/form/Form';
import { InputField, SelectField, TextAreaField } from '../../atoms/FormElement';

const NotificationSendPage = (props) => {

  const reactQuillRef = useRef(null);
  const [loading, setLoading] = useState(null);

  const { control, errors, reset, handleSubmit, setValue, getValues, register, setError } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    register("body", {
      required: true
    })
  }, [])
  useEffect(() => {
    console.log("errors", errors)
  }, [errors])


  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'notifications',
      breadcrumbName: 'Notification',
    },
    {
      path: '',
      breadcrumbName: 'Send',
    }
  ];

  const onFinish = (data: any) => {
    setLoading(true)
    const editor = reactQuillRef?.current?.getEditor()
    const unprivilegedEditor = reactQuillRef?.current?.makeUnprivilegedEditor(editor);
    let url = '/notification/send';

    const value = {
      body: getValues('body'),
      type: getValues('type')
    }
    axios.post(url, value)
      .then(function (response) {
        message.success(response?.data?.message)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        message.error(error?.response?.data?.message)
        message.warning('In Progress')
      });
  }


  return (
    <DashboardLayout>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
          <Col span={24}>
            <Card
              bordered={false}
              className="noicon-space"
            >
              <PageHeaderSection
                routes={routes}
                title={`Notifications`}
              />
            </Card>
          </Col>
        </Row>
        <Card bordered={false} className="noicon-space mt-1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
            <Col span={4} sm={6}>
              <SelectField
                isRequired={true}
                fieldname="type"
                label="Type"
                control={control}
                iProps={{ placeholder: 'Select Type' }}
                rules={{ required: "Please select Type" }}
                setValue={setValue}
                selectOption={[
                  {
                    label: 'Provider',
                    value: 'provider',
                  }, {
                    label: 'Patient',
                    value: 'patient',
                  }
                ]}
                validate={errors?.type && "error"}
                validMessage={errors?.type && errors?.type?.message}
              />

            </Col>
            <Col span={4} sm={8}>
              <InputField
                isRequired={true}
                fieldname="title"
                label="Title"
                control={control}
                rules={{
                  required: "Please enter Title",
                }}
                iProps={{ placeholder: "Title" }}
                initValue=""
                validate={errors?.title && "error"}
                setValue={setValue}
                validMessage={errors?.title && errors?.title?.message}
              />
            </Col>
            <Col span={24} className="mt-1">
              <Spin spinning={loading} >
                <TextAreaField
                  isRequired={false}
                  fieldname="body"
                  label="Message"
                  control={control}
                  initValue=""
                  iProps={
                    { rows: 12 }
                  }
                  setValue={setValue}
                  validate={errors?.body && "error"}
                  validMessage={errors?.body && errors?.body}
                />
                {errors?.body}
                <Button type="primary" htmlType="submit" className="mt-1" style={{ float: 'right' }}> Send</Button>
              </Spin>
            </Col>
          </Row>
        </Card>
      </Form>
    </DashboardLayout>
  )
};

export default NotificationSendPage;
