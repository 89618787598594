import * as action_types from "./constants";

const appointments = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.APPOINTMENTS_LIST:
      return {
        ...state,
        appointementList: data,
      };
    case action_types.APPOINTMENT_DETAILS:
      return {
        ...state,
        appointementDetails: data,
      };
    case action_types.APPOINTMENT_EDIT:
      return {
        ...state,
        appointmentEdit: data,
      };
    case action_types.APPOINTMENT_DELETE:
      return {
        ...state,
        appointmentDelete: data,
      };
    case action_types.APPOINTMENT_ADD_REVIEW:
      return {
        ...state,
        appointmentAddReview: data,
      };
    default:
      return state;
  }
};

export default appointments;
