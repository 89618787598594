import * as action_types from "./constants";

const states = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.COUNTRIES_LIST: 
        return {
          ...state,
          statesList: data,
        };
      default:
        return state;
    }

};

export default states;
