import React, { useState } from "react";
import { Layout, Row, Col, Typography, Image, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import storeLogo from "../../../assets/img/sos-logo.png";

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;


const LoginTemplate = (props) => {
  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout className="login-layout">
        <Row align="middle" justify="center"  style={{height:'100vh'}}>
            <Col lg={6} sm={24} xs={24} >
              <Row gutter={[0, 40]}>
                <Col span={24}>
                  <Space
                    direction="vertical"
                    size={16}
                    className="w-100"
                    align="center"
                  >
                    <Image preview={false} src={storeLogo} alt="eQari logo"  style={{width:'100px'}}/>
                    {/* <Text className="login-logo-slogan">
                      Lorem
                    </Text> */}
                  </Space>
                </Col>
                <Col span={24}>{props.children}</Col>
              </Row>
          </Col>
        </Row>
      </Layout>
    </Spin>
  );
};

export default LoginTemplate;
