import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getSymptomsList = (param={}) => {
  return async (dispatch) => {
    try {
      let url = 'symptoms/list';
      const data  = await axios.get(url,{params:param});
      dispatch({
        type: action_types.SYMPTOMS_LIST,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
