import { notification } from "antd";
import * as action_types from "./constants";

const notifications = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.NOTIFICATION_LIST:
      return {
        ...state,
        notificationViewerList: data,
      };
    case action_types.NOTIFICATION_VIEW:
      return {
        ...state,
        notificationsDetails: data,
      };
    case action_types.NOTIFICATION_DELETE:
      return {
        ...state,
        notificationsDelete: data,
      };
    default:
      return state;
  }
};

export default notifications;
