import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getProvidersList = (param = {}) => {
  return async (dispatch) => {
    try {
      let url = "provider/list";
      const data = await axios.get(url, { params: param });
      dispatch({
        type: action_types.PROVIDERS_LIST,
        data: data.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PROVIDERS_LIST,
        data: e,
      });
    }
  };
};

export const providersDelete = (param) => {
  return async (dispatch) => {
    try {
      let url = "provider/delete";
      const data = await axios.post(url, param);
      dispatch({
        type: action_types.PROVIDERS_DELETE,
        data: data?.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getProviderEdit = (id) => {
  return async (dispatch) => {
    try {
      let url = "provider/edit/" + id;
      const data = await axios.get(url);
      dispatch({
        type: action_types.PROVIDERS_EDIT,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PROVIDERS_EDIT,
        data: e?.response,
      });
    }
  };
};

export const providerUpdate = (id, params = null) => {
  return async (dispatch) => {
    try {
      let url = "provider/update/" + id;
      const data = await axios.put(url, params);
      dispatch({
        type: action_types.PROVIDERS_UPDATE,
        data: data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PROVIDERS_UPDATE,
        data: e,
      });
    }
  };
};

export const providerStatusUpdate = (id, params = null) => {
  return async (dispatch) => {
    try {
      let url = "provider/status/update/" + id;
      const data = await axios.put(url, params);
      dispatch({
        type: action_types.PROVIDERS_STATUS_UPDATE,
        data: data,
      });
    } catch (e) {
      dispatch({
        type: action_types.PROVIDERS_STATUS_UPDATE,
        data: e,
      });
    }
  };
};
