import { Card, Col, Divider, Image, Row, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const PatientEditLeftSideBar = props => {

  const data = props?.data;

  return (
    <Card
     bordered={false} 
     className="noicon-space mt-1 provider-sidebar"
     cover={
      data?.user?.avatar?.isAvatar ?
        <Image
        src={data?.user?.avatar?.medium}
        preview={{
          src:data?.user?.avatar?.avatar
        }}
        className="provider-avatar"
      />:
      <Image
      src={data?.user?.avatar?.medium}
      preview={false}
    /> 
    }
     >
     <Meta
      title="Age"
      description={data?.user?.age}
    />
    <Divider className={"ag-primary-bg"}/>
{/*     
      <Meta
      className="mt-1"
        avatar={
          data?.driving_license_img && <Image
          width={50}
          src={data?.driving_license_img}
          preview={{
            src: data?.driving_license_img,
          }}
        />
      }
      title="Drivers License"
      description={data?.driving_license_no}
    />
  <Meta
      className="mt-1"
      title="Issued State"
      description={data?.driver_issued_state?.name}
    />
    <Divider className={"ag-primary-bg"}/>
  <Meta
      className="mt-1"
        avatar={
          data?.medical_licence_img && <Image
          width={50}
          src={data?.medical_licence_img}
          preview={{
            src: data?.medical_licence_img,
          }}
        />
      }
      title="Medical or Profressional License"
      description={data?.medical_licence_no}
    />
     <Meta
      className="mt-1"
      title="Expiration Date"
      description={data?.medical_expiration_date}
    />
     <Meta
      className="mt-1"
      title="Issued State"
      description={data?.medical_issued_state?.name}
    />
<Divider className={"ag-primary-bg"}/>
    <Meta
      className="mt-1"
        avatar={
          data?.medical_malpractie_img && <Image
          width={50}
          src={data?.medical_malpractie_img}
          preview={{
            src: data?.medical_malpractie_img,
          }}
        />
      }
      title="Malpractice Insurance Information"
      description={data?.medical_malpractie_no}
    /> */}
     
   
   
      {/* <Row gutter={24}>
        <Col span={8}>
         
          <Typography>Driving License</Typography>
          {data?.driving_license_img ?
          <Image
            width={100}
            src={data?.driving_license_img}
            preview={{
              src: data?.driving_license_img,
            }}
          /> :data?.driving_license_no
          }
        </Col>
      </Row> */}
    </Card>
  );
};

export default PatientEditLeftSideBar;
