import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getSpecialitiesList = (param = {}) => {
  return async (dispatch) => {
    let url = "specialities/list";
    const data = await axios.get(url, { params: param });
    dispatch({
      type: action_types.SPECIALITIES_LIST,
      data: data.data,
    });
  };
};

export const resetState = (param = {}) => {
  return async (dispatch) => {
    try {
      dispatch(param);
    } catch (e) {
      console.log("Err", e);
    }
  };
};
