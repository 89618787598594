import { Button, Col, DatePicker, Input, Row, Select, Table, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AppointmentStatusTag from '../../atoms/AppointmentStatusTag';

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const SosGrid = props => {

  const {
    moreFilterProps,
    tagRender,
    setFirstDropDownProps,
    isBulkDelete, onDeleteSuccess,
    rowKey, onDelete, onSearch,
    dataSource, columns, loading,
    pagination, onChangeGrid,
    setFirstDropDownOption, setFirstDropDownPlaceHolder, onChangeFirstDropDown, handelDateRange, selectDefaultValue } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [datePicketRangeOpen, setDatePicketRangeOpen] = useState(false)
  const [searchField, setSarchField] = useState(false)

  const hasSelected = selectedRowKeys?.length > 0;

  const onSelectChange = rows => {
    setSelectedRowKeys(rows);
  };

  const baseHandelDateRange = (value) => {
    handelDateRange(value)
  }

  useEffect(() => {
    setSarchField(false)
  }, [dataSource])

  useEffect(() => {
    if (onDeleteSuccess) {
      setSelectedRowKeys([])
    }
  }, [onDeleteSuccess])

  // const firtDropDownOptionGrid = [];
  // setFirstDropDownOption?.data?.map(function(item){
  //       firtDropDownOptionGrid.push({
  //         label: item.name,
  //         value: item.id,
  //       });
  // })

  const rowSelections = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const confirm = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete those items?',
      cancelText: 'Cancel',
      okText: 'Yes, delete it!',
      onOk: rowsDelete
    });
  }

  const rowsDelete = () => {
    onDelete(selectedRowKeys)
  }
  const onSearchFilter = (value) => {
    onSearch(value)
    // if(value){
    //   onSearch(value)
    // }else{
    //   setSarchField(true)
    // }
  }

  const orderListByKey = (data, key, order) => {
    const compareValues = (key, order = "asc") => (elemA, elemB) => {
      if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key)) return 0;
      const comparison = elemA[key].localeCompare(elemB[key]);
      return order === "desc" ? comparison * -1 : comparison;
    };
    return data.sort(compareValues(key, order));
  }

  return (

    <>
      <Row className="mb-1" gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify={'end'}>

        <Col style={{ marginLeft: 0, marginRight: 'auto' }} sm={6}>
          {isBulkDelete &&
            <Button type="primary" className="mr-1" disabled={!hasSelected} onClick={confirm}>
              Delete
            </Button>
          }

          {handelDateRange && <RangePicker onChange={baseHandelDateRange} />}
        </Col>
        {moreFilterProps}
        {onChangeFirstDropDown &&
          <Col span={4} sm={24} xs={24} md={6}>
            <Select
              style={{ width: '100%' }}
              placeholder={setFirstDropDownPlaceHolder}
              onChange={onChangeFirstDropDown}
              options={orderListByKey(setFirstDropDownOption, 'label', 'asc')}
              allowClear
              showArrow
              tagRender={tagRender}
              {...setFirstDropDownProps}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Col>
        }
        {onSearch &&
          <Col span={4} sm={24} xs={24} md={6}>
            <Search placeholder="Search..." allowClear onSearch={onSearchFilter} enterButton />
            {/* {searchField && <Text type="danger">Search field is required</Text> } */}
          </Col>
        }
      </Row>

      <Table
        rowSelection={isBulkDelete && rowSelections}
        pagination={{ ...pagination, ...{ showSizeChanger: true } }}
        loading={loading}
        onChange={onChangeGrid}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 245 }}
        rowKey={rowKey}
      />
    </>
  );
};

export default SosGrid;
