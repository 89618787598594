import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getTotalEarnings = (param={}) => {
  return async (dispatch) => {
    try {
      let url = 'total-earnings';
      const data  = await axios.get(url,{params:param});
      dispatch({
        type: action_types.TOTAL_EARNINGS,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};


export const getProvidersEarning = (param={}) => {
  return async (dispatch) => {
    try {
      let url = 'total-earnings/provider';
      const data  = await axios.get(url,{params:param});
      dispatch({
        type: action_types.TOTAL_EARNING_PROVIDER,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
