import * as action_types from "./constants";

const patients = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.PATIENTS_LIST: 
        return {
          ...state,
          patientList: data,
        };
      case action_types.PATIENTS_DELETE: 
        return {
          ...state,
          patientsDelete: data,
        };
      case action_types.PATIENTS_EDIT: 
        return {
          ...state,
          patientEdit: data,
        };
      case action_types.PATIENTS_UPDATE: 
        return {
          ...state,
          patientUpdate: data,
        };
      default:
        return state;
    }

};

export default patients;


