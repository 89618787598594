import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DollarOutlined,
  MailOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  const [select, setSelect] = useState("1");
  const [openKeys, setOpenKeys] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation()
  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const CustomTrigger = () =>
    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />;

  const onOpenChange = (options) => {
    console.log(options, 'options')
    setOpenKeys(options[1]);
  };

  return (
    <Sider
      width="236"
      trigger={<CustomTrigger />}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu
        theme="dark"
        openKeys={[openKeys]}
        onOpenChange={onOpenChange}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        <Menu.Item key="/dashboard" disabled={location.pathname == '/dashboard' && true} icon={<DashboardOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>

        <Menu.Item key="/providers" disabled={location.pathname == '/providers' && true} icon={<UserOutlined />}>
          <Link to="/providers">Providers</Link>
        </Menu.Item>

        <Menu.Item key="/staff" disabled={location.pathname == '/staff' && true} icon={<UserOutlined />}>
          <Link to="/staff">Staff</Link>
        </Menu.Item>

        <Menu.Item key="/patients" disabled={location.pathname == '/patients' && true} icon={<DashboardOutlined />}>
          <Link to="/patients">Patients</Link>
        </Menu.Item>

        <Menu.Item key="/influencer" disabled={location.pathname == '/influencer' && true} icon={<DashboardOutlined />}>
          <Link to="/influencer">Influencer</Link>
        </Menu.Item>

        <Menu.Item key="/appointments" disabled={location.pathname == '/appointments' && true} icon={<DashboardOutlined />}>
          <Link to="/appointments">Appointments</Link>
        </Menu.Item>

        <Menu.Item key="/transactions" disabled={location.pathname == '/transactions' && true} icon={<DashboardOutlined />}>
          <Link to="/transactions">Transactions</Link>
        </Menu.Item>

        <Menu.Item key="/specialities" disabled={location.pathname == '/specialities' && true} icon={<DashboardOutlined />}>
          <Link to="/specialities">Specialities</Link>
        </Menu.Item>

        <Menu.Item key="/symptoms" disabled={location.pathname == '/symptoms' && true} icon={<DashboardOutlined />}>
          <Link to="/symptoms">Symptoms</Link>
        </Menu.Item>

        <Menu.Item key="/notifications" disabled={location.pathname == '/notifications' && true} icon={<UserOutlined />}>
          <Link to="/notifications">Notifications</Link>
        </Menu.Item>

        <Menu.Item key="/notifications-send" disabled={location.pathname == '/notifications-send' && true} icon={<UserOutlined />}>
          <Link to="/notifications-send">Notification Send</Link>
        </Menu.Item>

        <Menu.Item key="/total-earnings" disabled={location.pathname == '/total-earnings' && true} icon={<DollarOutlined />}>
          <Link to="/total-earnings">Total Earnings</Link>
        </Menu.Item>

        <Menu.Item key="/mails" disabled={location.pathname == '/mails' && true} icon={<MailOutlined />}>
          <Link to="/mails">Mails</Link>
        </Menu.Item>

        <Menu.Item key="/Video" disabled={location.pathname == '/Video' && true} icon={<VideoCameraOutlined />}>
          <Link to="/Video">Video</Link>
        </Menu.Item>

        <Menu.Item key="/terms-and-condition" disabled={location.pathname == '/terms-and-condition' && true} icon={<DollarOutlined />}>
          <Link to="/terms-and-condition">Terms & Condition</Link>
        </Menu.Item>

        <Menu.Item key="/videos" disabled={location.pathname == '/videos' && true} icon={<SettingOutlined />}>
          <Link to="/videos">Videos</Link>
        </Menu.Item>

        <Menu.Item key="/settings" disabled={location.pathname == '/settings' && true} icon={<SettingOutlined />}>
          <Link to="/settings">Settings</Link>
        </Menu.Item>
        {/* <SubMenu key="County/State/City" icon={<UserOutlined />} title="County/State/City">
          <Menu.Item key="/countries"> <Link to="/countries">Country</Link></Menu.Item>
          <Menu.Item key="/states"> <Link to="/states">State</Link></Menu.Item>
          <Menu.Item key="/cities"> <Link to="/cities">City</Link></Menu.Item>
        </SubMenu> */}

      </Menu>
    </Sider>
  );
};

export default Sidebar;
