import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, PageHeader, Descriptions, Spin, Switch, Tag, Rate, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserOutlined, FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import DashboardLayout from '../../../molecules/DashboardLayout';
import PageHeaderSection from '../../../molecules/PageHeaderSection';
import { useParams } from "react-router-dom";
import { Content } from 'antd/lib/layout/layout';
import Form from 'antd/lib/form/Form';
import { useForm } from "react-hook-form";
import { DateField, InputField, MaskInputField, SelectField, TextAreaField } from '../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffEdit, staffUpdate, staffStatusUpdate } from '../ducks/actions'
import ProviderEditLeftSideBar from '../../../molecules/ProviderEditLeftSideBar';
import moment from 'moment';
import { getStatesList } from '../../States/ducks/actions';
import { getCitiesList } from '../../Cities/ducks/actions';
import { getSpecialitiesList, resetState } from '../../Specialties/ducks/actions';
import { BASE_URL } from '../../../../configs/constants';
import SosGrid from '../../../molecules/SosGrid';
import AppointmentStatusTag from '../../../atoms/AppointmentStatusTag';


const StaffEdit = props => {
    const params = useParams()
    const dispatch = useDispatch();
    const [staffData, setStaffData] = useState<any>({
        routing_number: null,
        bank_name: null,
        business_name: null,
        speciality: null,
        user: null,
        average_ratings: null,
        bookings: [],
        provider_type: null,
        created_at: null,
        account_number: null,
        user_id: null,
        speciality_id: null,
        totalEarnings: null,
        tax_id: null,
        specialities: []
    });
    const [loading, setLoading] = useState(null);
    const [isInvalidProvider, setIsInvalidProvider] = useState(false);
    const [providerType, setProviderType] = useState(null);

    const { control, errors, reset, handleSubmit, setValue, watch, register, setError } = useForm({
        mode: 'onChange',
    });


    const staffEditData = useSelector(
        (state: any) => state.staff.staffEdit,
    );


    const staffUpdateData = useSelector(
        (state: any) => state.staff.staffUpdate,
    );

    const staffStatusUpdateData = useSelector(
        (state: any) => state.staff.staffStatusUpdate,
    );

    useEffect(() => {
        setLoading(true)
        dispatch(getStaffEdit(params?.staff_id))
    }, [])

    useEffect(() => {
        if (staffEditData?.data?.data) {
            setStaffData(staffEditData?.data?.data)
            setLoading(false)
            dispatch(resetState({
                type: 'STAFF_EDIT',
                data: null
            }))
        }
        if (staffEditData?.status == 400) {
            setLoading(false)

            if (staffEditData?.data?.data?.data?.error == 'INVALID_ID') {
                setIsInvalidProvider(true)
            }


            message.error(staffEditData?.data?.message)
        }
    }, [staffEditData])


    const routes = [
        {
            path: '/dashboard',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '/staff',
            breadcrumbName: 'Staff',
        },
        {
            path: 'id',
            breadcrumbName: params.staff_id
        }
    ];

    const provideStatusChange = (value) => {
        setLoading(false)
        setLoading(true)
        let param = {
            status: value ? 'approved' : 'disapproved'
        }
        dispatch(staffStatusUpdate(params?.staff_id, param));
    }

    useEffect(() => {
        if (staffStatusUpdateData?.data?.message) {
            setLoading(false)
            dispatch(getStaffEdit(params?.staff_id))
            dispatch(resetState({
                type: 'STAFF_STATUS_UPDATE',
                data: null
            }))
            dispatch(resetState({
                type: 'STAFF_STATUS_UPDATE',
                data: null
            }))

            message.success(staffStatusUpdateData?.data?.message)
        }
    }, [staffStatusUpdateData])

    useEffect(() => {
        if (staffUpdateData?.data?.status) {
            message.success(staffUpdateData?.data?.message)
            dispatch(getStaffEdit(params?.staff_id))

            dispatch(resetState({
                type: 'STAFF_UPDATE',
                data: null
            }))

        } else if (staffUpdateData?.data) {
            message.error(staffUpdateData?.data?.message)
        }
    }, [staffUpdateData])

    const onFinish = async (values) => {
        setLoading(false)
        setLoading(true)
        values.email = null;
        dispatch(staffUpdate(params?.staff_id, values));
    }

    let color = ''
    if (staffData?.availability == 'online')
        color = 'green'
    if (staffData?.availability == 'offline')
        color = 'red'
    if (staffData?.availability == 'busy')
        color = 'yellow'

    const userStatus = {
        'pending': 'blue',
        'approved': 'green',
        'disapproved': 'red'
    }

    const providerHandle = (value) => {
        setProviderType(value)
    }

    return <DashboardLayout>

        <Spin spinning={loading} >

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
                <Col span={24}>
                    <Card bordered={false} className="noicon-space" >
                        <PageHeaderSection
                            routes={routes}
                            title={staffData?.full_name}
                            subTitle={<>
                                <AppointmentStatusTag status={staffData?.status} type="admin" />
                                <AppointmentStatusTag status={staffData?.meta?.[0]?.value} />
                            </>}
                            extra={[
                                <span key="4">
                                    <Rate disabled allowHalf value={parseFloat(staffData?.average_ratings)} />
                                    <span className="ant-rate-text">{staffData?.average_ratings && parseFloat(staffData?.average_ratings).toFixed(1)}</span>
                                </span>,
                                <Button key="1" type="primary" disabled={isInvalidProvider} >
                                    <Link to={{
                                        pathname: `/appointments`,
                                        state: { staff_id: staffData.id }
                                    }}>
                                        Appointment
                                    </Link>
                                </Button>,
                                <Switch key="3"
                                    disabled={isInvalidProvider}
                                    checkedChildren="Approve"
                                    checked={staffData?.status == 'approved' ? true : false}
                                    unCheckedChildren="Disapprove" style={{ marginLeft: '10px' }} onChange={provideStatusChange} />
                            ]}
                            tags={<Tag color={color}>{staffData?.availability}</Tag>}
                        >
                            <Descriptions size="small" column={4}>
                                <Descriptions.Item label="Registerd At">{moment.utc(staffData?.created_at).format("llll")}</Descriptions.Item>
                                <Descriptions.Item label="Total Appointment">{staffData?.staff_bookings?.length}</Descriptions.Item>
                                <Descriptions.Item label="Service House Call"> <AppointmentStatusTag status={staffData?.provider_service_housecall == true ? 'true' : 'false'} /></Descriptions.Item>
                                <Descriptions.Item label="Service Teleconsultant"> <AppointmentStatusTag status={staffData?.provider_service_teleconsultant == true ? 'true' : 'false'} /></Descriptions.Item>
                            </Descriptions>
                        </PageHeaderSection>
                    </Card>
                </Col>
            </Row>

            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>
                    <Col span={6} xs={24} sm={24} md={6} xxl={6}>
                        <ProviderEditLeftSideBar data={staffData} />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={18} xxl={18}>
                        <Card bordered={false} className="noicon-space mt-1">
                            <Row gutter={24}>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="first_name"
                                        label="First Name"
                                        control={control}
                                        rules={{
                                            required: "Please enter First name",
                                        }}
                                        iProps={{ placeholder: "First Name", maxLength: "50" }}
                                        initValue=""
                                        validate={errors?.first_name && "error"}
                                        valueGot={staffData?.first_name}
                                        setValue={setValue}
                                        validMessage={errors?.first_name && errors?.first_name?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="last_name"
                                        label="Last Name"
                                        control={control}
                                        rules={{
                                            required: "Please enter Last name",
                                        }}
                                        iProps={{ placeholder: "Last Name", maxLength: "50" }}
                                        initValue=""
                                        validate={errors?.last_name && "error"}
                                        valueGot={staffData?.last_name}
                                        setValue={setValue}
                                        validMessage={errors?.last_name && errors?.last_name.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="email"
                                        label="Email"
                                        control={control}
                                        iProps={{ disabled: true, readOnly: true }}
                                        valueGot={staffData?.email}
                                        setValue={setValue}
                                    />
                                </Col>
                                <Col span={24} xs={24} sm={24} md={24} xxl={24}>

                                    {!isInvalidProvider && <Button type="primary" htmlType="submit">Update</Button>}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24} className="mt-1">
                        {window.location.hostname == 'localhost' && <SosGrid
                            loading={loading}
                            columns={[
                                {
                                    title: 'Ip Address',
                                    dataIndex: 'ip'
                                },
                                {
                                    title: 'Country',
                                    dataIndex: 'country'
                                },
                                {
                                    title: 'Region',
                                    dataIndex: 'region'
                                },
                                {
                                    title: 'City',
                                    dataIndex: 'city'
                                },
                                {
                                    title: 'Browser',
                                    dataIndex: 'browser'
                                },
                                {
                                    title: 'Device Type',
                                    dataIndex: 'device_type'
                                },
                                {
                                    title: 'Device',
                                    dataIndex: 'device'
                                },
                                {
                                    title: 'Platform',
                                    dataIndex: 'platform'
                                },
                                {
                                    title: 'Current',
                                    dataIndex: 'is_current',
                                    render: (text, record) => {
                                        return <AppointmentStatusTag status={record.is_current ? 'online' : 'offline'} />
                                    }
                                },
                                {
                                    title: 'Created At',
                                    dataIndex: 'created_at',
                                    render: (text, record) => {
                                        return moment.utc(record.created_at).format('llll'); // just for decoration
                                    }
                                }
                            ]}
                            dataSource={staffData?.logins}
                        />}
                    </Col>
                </Row>
            </Form>
        </Spin>

    </DashboardLayout >
}

export default StaffEdit;