import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  Checkbox,
  TimePicker,
  Upload,
  Switch,
} from "antd";
import { Controller } from "react-hook-form";
import { default as RSelect } from "react-select";
import { MaskedInput  } from "antd-mask-input";

const { Option } = Select;

export const InputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ onBlur, value, onChange }) => (
            <Input
              value={value}
              onChange={onChange}
              onBlur={props.onBlur}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const InputPassword = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          as={<Input.Password {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const SelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    validMessage,
    fieldKey,
    field
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
    
      {...field}
    >
      <Controller
        name={fieldname}
        fieldKey={fieldKey}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ onBlur, value, onChange }) => (
          <Select
            value={value}
            styles={{
              control: (val) => ({ ...val, minHeight: 32 }),
              valueContainer: (vcontain) => ({ ...vcontain, padding: "0 8px", textTransform: 'capitalize' }),
              dropdownIndicator: (icontain) => ({ ...icontain, padding: 5 }),
              indicatorSeparator: (icontain) => ({
                ...icontain,
                backgroundColor: "#fff",
              }),
              option: (vcontain) => ({ ...vcontain, textTransform: 'capitalize' }),
              placeholder: (place) => ({ ...place, color: "rgba(0,0,0,.3)" }),
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            {...iProps}
            options={selectOption}
          />
        )}
      />
    </Form.Item>
    // <Form.Item
    //   required={isRequired ? isRequired : false}
    //   label={label}
    //   validateStatus={validate}
    //   help={validMessage}
    //   noStyle={props.noStyle}
    // >
    //   {iProps?.placeholder && <Text className="custom-placeholder">{iProps.placeholder}</Text>}
    //   <Controller
    //     name={fieldname}
    //     control={control}
    //     defaultValue={initValue ? initValue : ""}
    //     rules={rules}
    //     as={<Select className="with-place" {...iProps}>{selectOption}</Select>}
    //   />
    // </Form.Item>
  );
};

export const ReactSelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    valueGot,
    validMessage,
  } = props;

  useEffect(() => {
    valueGot && props.setValue(fieldname, valueGot);
  }, [valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.classes}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ onBlur, value, onChange }) => (
          <RSelect
            value={value}
            styles={{
              control: (val) => ({ ...val, minHeight: 32 }),
              valueContainer: (vcontain) => ({
                ...vcontain,
                padding: "0 8px",
                textTransform: "capitalize",
              }),
              dropdownIndicator: (icontain) => ({ ...icontain, padding: 5 }),
              indicatorSeparator: (icontain) => ({
                ...icontain,
                backgroundColor: "#fff",
              }),
              option: (vcontain) => ({
                ...vcontain,
                textTransform: "capitalize",
              }),
              placeholder: (place) => ({ ...place, color: "rgba(0,0,0,.3)" }),
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            {...iProps}
            options={selectOption}
            theme={(theme) => ({
              ...theme,
              borderRadius: 2,
              colors: { ...theme.colors, primary: "#767676" },
            })}
          />
        )}
      />
    </Form.Item>
  );
};

export const DateField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          as={<DatePicker style={{ width: "100%" }} {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const TimeField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          as={<TimePicker style={{ width: "100%" }} {...iProps} />}
        />
      </Form.Item>
    </>
  );
};

export const InputCheckbox = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      valuePropName="checked"
      noStyle
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ value, onChange }) => (
          <Checkbox
            checked={value}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          >
            {label}
          </Checkbox>
        )}
      />
    </Form.Item>
  );
};

export const UploadField = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    filelist,
    fileProps,
    iProps,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ value, onChange }) => (
          <Upload {...fileProps}>
            <Input
              {...iProps}
              value={
                filelist && filelist[0] && filelist[0].name
                  ? filelist[0].name
                  : "Choose file"
              }
              addonAfter="Browse"
            />
          </Upload>
        )}
      />
    </Form.Item>
  );
};

export const TextAreaField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.classes}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ""}
        rules={rules}
        as={<Input.TextArea {...iProps} />}
      />
    </Form.Item>
  );
};

export const SwitchField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ onBlur, value, onChange }) => (
            <Switch
              checked={value}
              onChange={onChange}
              onBlur={props.onBlur}
              {...iProps} />
          )}
        />
      </Form.Item>
    </>
  );
};

export const MaskInputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.classes}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          as={<MaskedInput {...iProps} />}
        />
      </Form.Item>
    </>
  );
};