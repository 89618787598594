import * as action_types from "./constants";

const totalEarnings = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.TOTAL_EARNINGS: 
        return {
          ...state,
          totalEarningsData: data,
        };
      case action_types.TOTAL_EARNING_PROVIDER: 
        return {
          ...state,
          providerTotalEarningsData: data,
        };
      default:
        return state;
    }

};

export default totalEarnings;
