import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch} from "react-redux";
import {
  Row,
  Col,
  Layout,
  Menu,
  Image,
  Space,
  Button,
  Typography,
  Dropdown,
} from "antd";
import {
  LogoutOutlined,UserOutlined
} from "@ant-design/icons";
import { deleteCookie, getCurrentUser, setCurrentUser } from "../../../utils/helper";
import sosLogo from "../../../assets/img/sos-logo.png";
import Avatar from "antd/lib/avatar/avatar";

const { Header } = Layout;
const { Text } = Typography;

const HeaderBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = () => {
    window.localStorage.clear();
    setCurrentUser(null);
    window.location.reload() 
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          onClick={logoutHandler}
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="site-header">
      <Row>
        <Col flex={200}>
          <Image src={sosLogo} preview={false} width={85} alt="SOS logo" />
        </Col>
        <Col flex="auto">
        <Space size={12}>
            <Dropdown overlay={menu} placement="bottomRight">
              <Space>
                <Avatar size={24} icon={<UserOutlined />} />
                <Text>
                  {
                    getCurrentUser()?.data?.user?.name
                  }
                </Text>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
