import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, Typography, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { getSpecialitiesList } from '../Specialties/ducks/actions'
import { UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Popup } from '../../atoms/Popup';
import UpdateSpeciality from './UpdateSpeciality';
import { Tag, Divider } from 'antd';
import AddSpeciality from './AddSpeciality';



const Specialities = (props) => {

  const dispatch = useDispatch();
  const [rows, setRows] = useState([])
  const [specialitiesData, setSpecialitiesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const [visible, setVisible] = useState(null)
  const [trigger, setTrigger] = useState(null)
  const [popupData, setPopupData] = useState({})
  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 10,
    status: "active"
  })
  const [onClose, setOnClose] = useState(false);
  const { Title } = Typography;

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    status: "active"
  })

  const providerDelete = useSelector(
    (state: any) => state.providers.providersDelete,
  );

  const specialitiesList = useSelector(
    (state: any) => state.specialities.specialitiesList?.data?.data,
  );

  useEffect(() => {
    setLoading(true)
    dispatch(getSpecialitiesList(pagination))
  }, []);

  useEffect(() => {
    if (specialitiesList?.data) {
      setPagination({
        ...pagination, ...{
          current: specialitiesList?.current_page,
          total: specialitiesList?.total,
          pageSize: specialitiesList?.per_page
        }
      })
      setRows(specialitiesList?.data)
      setLoading(false)
    }
  }, [specialitiesList])

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value?.current,
      pageSize: value?.pageSize,
      order: sorter?.order,
      field: sorter?.field,
    }
    setParams({ ...params, ...param })
  };


  const actionEdit = (text, params) => {
    return (
      <>
        <Space size={16}>
          <Tooltip placement="top" title={'Edit'}><Button type="link"
            onClick={() => handleUpdate(params)}
            className="p-0 ag-success">
            <EditOutlined />
          </Button>
          </Tooltip>
          {/* <Button type="link"   danger className="p-0">
            <DeleteOutlined style={{ color: '#FB7171' }}    onClick={() => deleteTimeSlots(params)} />
          </Button> */}
        </Space>
      </>
    )
  }
  const handleUpdate = (params) => {
    setPopupData(params)
    setVisible(true);
    setTrigger(0);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return <Tag color={record?.status == 'active' ? 'green' : 'red'}>{record?.status == 'active' ? 'Active' : 'Inactive'}</Tag>
      }

    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        return moment.utc(record.created_at).format('LLLL'); // just for decoration
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: actionEdit,
    },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Update a Speciality
        </Title>
      ),
      content: (
        <UpdateSpeciality
          setVisible={setVisible}
          onClose={onClose}
          setOnClose={setOnClose}
          popupData={popupData}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add a Speciality
        </Title>
      ),
      content: (
        <AddSpeciality
          setVisible={setVisible}
          onClose={onClose}
          setOnClose={setOnClose}
        />
      ),
      width: 650,
    }
  ];


  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger]?.title,
    content: popup[trigger]?.content,
    width: popup[trigger]?.width,
    onCancel: () => onCancel(),
  };


  const onCancel = () => {
    setOnClose(true)
    setVisible(false)
  }

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setLoading(true)
      dispatch(getSpecialitiesList(params))
    }
  }, [params])

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'specilities',
      breadcrumbName: 'Specilities',
    }
  ];

  const addNew = () => {
    setVisible(true);
    setTrigger(1);
  }

  const onChangeFirstDropDown = (value) => {
    let param = {
      status: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Specilities | ${pagination?.total}`}
              extra={[
                <Button key="3" type="primary" onClick={addNew}>Add New</Button>,
              ]}
            />
          </Card>
        </Col>
        <Col span={24} className="mt-1">
          <Card
            bordered={false}
            className="noicon-space"
          >
            <SosGrid
              pagination={pagination}
              loading={loading}
              onChangeGrid={onChangeGrid}
              columns={columns}
              dataSource={rows}
              setFirstDropDownOption={[
                {
                  label: "Active",
                  value: "active"
                },
                {
                  label: "Inactive",
                  value: "inactive"
                }
              ]}
              setFirstDropDownPlaceHolder={'Select status'}
              onChangeFirstDropDown={onChangeFirstDropDown}
              onSearch={(value) => onSearch(value)}
              rowKey={'id'}
            />
          </Card>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </DashboardLayout>
  );
};

export default Specialities;
