import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getDashboardIndex = () => {
  return async (dispatch) => {
    try {
      let url = 'dashboard/index';
      const data = await axios.get(url);
      dispatch({
        type: action_types.DASHBOARD,
        data: data.data,
      });
    } catch (e) {
      if (e?.response?.data?.message == 'Unauthenticated') {
        localStorage.removeItem("sos_admin");
        location.reload();
      }
    }
  };
};
