import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin, Switch } from "antd";
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { InputField, ReactSelectField, SelectField, SwitchField } from "../../atoms/FormElement";
import axios from "../../../utils/axiosInceptor";
import { useDispatch, useSelector } from 'react-redux';
import { getSpecialitiesList } from '../Specialties/ducks/actions'

import moment from 'moment';
import { Popup } from "../../atoms/Popup";

const { Paragraph } = Typography;
const defaultValues: any = {
  id: null,
  name: null,
  status: null
};

const UpdateSpeciality = (props) => {

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (props?.popupData) {
      const { popupData: { id, name, status, is_hrs } } = props
      console.log("is_hrs", is_hrs)
      setFormData({ id: id, name: name, status: status, is_hrs: is_hrs == false ? 'false' : 'true' })
    }
  }, [props?.popupData])

  useEffect(() => {
    console.log(formData)
  }, [formData])

  const resetForm = () => {
    reset({ defaultValues });
    props?.setOnClose(false)
  }

  const onFinish = async (value) => {

    setSubmited(true);
    setLoader(true);
    value.is_hrs = value.is_hrs == 'false' ? false : true
    try {
      let url = `/specialities/update/${formData?.id}`
      await axios.put(url, value);
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Specility update successfully");
        dispatch(getSpecialitiesList({
          pageSize: 10
        }))
        props.setVisible(false)
      }, 3000);
    }
    catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }

  };


  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label={"Name"}
                control={control}
                rules={{
                  required: "Please enter specility name.",
                }}
                iProps={{ placeholder: "Specility Name" }}
                setValue={setValue}
                valueGot={formData?.name}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="is_hrs"
                label="Is Hours?"
                control={control}
                iProps={{ placeholder: 'Is Hrs' }}
                rules={{ required: "Please select is hours" }}
                valueGot={formData?.is_hrs}
                setValue={setValue}
                selectOption={[
                  { label: 'True', value: 'true' },
                  { label: 'False', value: 'false' },
                ]}
                validate={errors?.is_hrs && "error"}
                validMessage={errors?.is_hrs && errors?.is_hrs?.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="status"
                label="Status"
                control={control}
                iProps={{ placeholder: 'Select Status' }}
                rules={{ required: "Please select status" }}
                valueGot={formData?.status}
                setValue={setValue}
                selectOption={[
                  { label: 'Active', value: 'active' },
                  { label: 'InActive', value: 'inactive' },
                ]}
                validate={errors?.status && "error"}
                validMessage={errors?.status && errors?.status?.message}
              />
              {/* <SwitchField
                isRequired={true}
                fieldname="status"
                label={"Status"}
                control={control}
                iProps={{ placeholder:  "Status Name" }}
                setValue={setValue}
                valueGot= {formData?.status == 'active' ? true : false}
                initValue=""
                validate={errors?.status && "error"}
                validMessage={errors?.status && errors?.status?.message}
              /> */}
            </Col>

          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={() => {
                  props.setVisible(false)
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default UpdateSpeciality;
