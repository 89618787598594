import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getCountriesList = (param={},baseUrl=null) => {
  return async (dispatch) => {
    try {

      let url = null;
      if(!baseUrl){
        url = 'list/countries';
      }

      const data  = await axios.get(baseUrl,{params:param});
      dispatch({
        type: action_types.COUNTRIES_LIST,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
