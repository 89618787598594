import * as action_types from "./constants";

const influencer = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.INFLUENCER_LIST: 
        return {
          ...state,
          influencerList: data,
        };
      case action_types.INFLUENCER_DELETE: 
        return {
          ...state,
          influencerDelete: data,
        };
      case action_types.INFLUENCER_EDIT: 
        return {
          ...state,
          influencerEdit: data,
        };
      case action_types.INFLUENCER_UPDATE: 
        return {
          ...state,
          influencerUpdate: data,
        };
      case action_types.INFLUENCER_STATUS_UPDATE: 
        return {
          ...state,
          influencerStatusUpdate: data,
        };
      default:
        return state;
    }

};

export default influencer;
