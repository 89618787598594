import { Row, Col, Card, List, Typography, Button, Descriptions, Space, Rate, Spin, Drawer, message } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../molecules/DashboardLayout';
import PageHeaderSection from '../../../molecules/PageHeaderSection';
import { getNotificationDetails } from '../ducks/actions'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {  useParams } from "react-router-dom";
import AppointmentStatusTag from '../../../atoms/AppointmentStatusTag';
import Avatar from 'antd/lib/avatar/avatar';
import axios from 'axios';
import { BASE_URL } from '../../../../configs/constants';
import Editor from "@monaco-editor/react";
import { Link, useHistory } from "react-router-dom";

const { Paragraph, Text, Title } = Typography;

const routes = [
  {
    path: '/dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/notifications',
    breadcrumbName: 'Notifications',
  },
  {
    path: 'notifications-details',
    breadcrumbName: 'Details',
  }
];

const data = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];

const NotificationViewDetails = props => {

  const dispatch = useDispatch();
  const params = useParams()
  const [loading, setLoading] = useState(null);
  const [details, setDetails] = useState(null);
  const [viewChatStatus, setViewChatStatus] = useState(false);

  const notificationsDetails = useSelector(
    (state: any) => state.notifications.notificationsDetails,
  );


  let history = useHistory();
  useEffect(() => {
    let url = "provider/list";
    const data = axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired", 5);
        history.push("/login");
      }
    });
  }, []);


  useEffect(() => {
    setLoading(true)
    dispatch(getNotificationDetails({
      id: params?.id
    }));
  }, []);

  useEffect(() => {
    setDetails({});
    console.log("notificationsDetails", notificationsDetails)
    if (notificationsDetails?.data?.data) {
      setLoading(false)
      setDetails(notificationsDetails?.data?.data)
    }
  }, [notificationsDetails])


  const onClose = () => {
    setViewChatStatus(false);
  };

  const onViewBooking = () => {
    setViewChatStatus(true);
  }

  return <DashboardLayout>
    <Spin spinning={loading} delay={500}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              subTitle={<AppointmentStatusTag status={details?.status} />}
              title={`Notification Details`}
              extra={[
                <Button key="1" type="primary">
                  <Link to={'/appointment/view/' + details?.data?.id + '/db04e1d2-fb6e-4f08-8f6e-2db2657d07db'}>
                    View Booking
                  </Link>
                </Button>,
              ]}
            >
              <Descriptions size="small" column={4}>
                <Descriptions.Item label="Created At">{moment(details?.created_at).format("LLLL")}</Descriptions.Item>
              </Descriptions>
            </PageHeaderSection>
          </Card>
        </Col>
        <Col span={6} className="mt-1">
          <Card
            title="Sender"
            className="appointment-detail-avatar"
            cover={<img alt={details?.sender?.full_name} src={details?.sender?.avatar?.medium} />}
            actions={[
              <Button type="primary" className="no-border" block>
                {
                  details?.sender?.type == 'patient' &&
                  <Link to={`/patient/edit/${details?.sender?.patient?.id}`}> View Profile </Link>
                }

                {
                  details?.sender?.type == 'provider' &&
                  <Link to={`/provider/edit/${details?.sender?.provider?.id}`}> View Profile </Link>
                }
              </Button>,
            ]}
          >
            <Meta title="Full Name" description={details?.sender?.full_name} />
          </Card>
        </Col>
        <Col span={6} className="mt-1">
          <Card
            title="Receiver"
            className="appointment-detail-avatar"
            cover={<img alt={details?.receiver?.full_name} src={details?.receiver?.avatar?.medium} />}
            actions={[
              <Button type="primary" className="no-border" block>

                {
                  details?.receiver?.type == 'patient' &&
                  <Link to={`/patient/edit/${details?.receiver?.patient?.id}`}> View Profile </Link>
                }

                {
                  details?.receiver?.type == 'provider' &&
                  <Link to={`/provider/edit/${details?.receiver?.provider?.id}`}> View Profile </Link>
                }



              </Button>,
            ]}
          >
            <Meta title="Full Name" description={details?.receiver?.full_name} />
          </Card>
        </Col>
        <Col span={12} className="mt-1">
          <Card>
            {
              details?.data && <Editor
                height="40vh"
                defaultLanguage="JSON"
                value={JSON.stringify(details, null, '\t')}
                defaultValue={JSON.stringify(details, null, '\t')}
              />
            }
          </Card>
        </Col>
      </Row>
    </Spin>
    <ChatDrawer viewChatStatus={viewChatStatus} onClose={onClose} id={params?.id} />
  </DashboardLayout>;
}

export default NotificationViewDetails;


const ChatDrawer = props => {

  const { viewChatStatus, onClose, id } = props;
  const [loading, setLoading] = useState(null);
  const [chatList, setChatList] = useState([]);

  const getChatList = async () => {
    setLoading(true)
    try {
      let url = 'appointments/view';
      const data = await axios.get(BASE_URL + 'api/chat', { params: { booking_id: id } }).then(function (response) {
        setLoading(false)
        setChatList(response?.data?.data?.data);
      });
    } catch (e) {
      setLoading(false)
      console.log("Err", e);
    }
  }

  useEffect(() => {
    getChatList()
  }, [id])

  return <Drawer
    title="View Chat"
    width={640}
    placement="right"
    closable={false}
    visible={viewChatStatus}
    onClose={onClose}
    className={'appointment-chat'}
  >
    <Spin spinning={loading} delay={500}>
      <List
        itemLayout="horizontal"
        dataSource={chatList}
        size={'small'}
        renderItem={item => (



          <>{
            item?.data?.[0]?.sender_id == item?.patient_id ?
              <List.Item className="patient-chat-item">
                <Text className="user_type"><span>Patient</span></Text>
                <List.Item.Meta
                  avatar={<Avatar src={item?.patient?.avatar?.thumb} />}
                  title={item?.patient?.full_name + ' | ' + moment(item?.created_at).format('llll')}
                  description={item?.data?.[0]?.message}
                />
              </List.Item> :
              <List.Item className="provider-chat-item">
                <Text className="user_type"><span>Provider</span></Text>
                <List.Item.Meta
                  avatar={<Avatar src={item?.provider?.avatar?.thumb} />}
                  title={moment(item?.created_at).format('llll') + ' | ' + item?.provider?.full_name}
                  description={item?.data?.[0]?.message}
                />
              </List.Item>
          }</>


        )}
      />
    </Spin>
  </Drawer>

}