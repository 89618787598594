/* eslint-disable react/prop-types */
import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { checkCookie } from "../utils/helper";

import Dashboard from "../app/modules/Dashboard";
import Providers from "../app/modules/Providers";
import ProviderEdit from "../app/modules/Providers/Form/Edit";
import PatientEdit from "../app/modules/Patients/Form/Edit";
import Appointments from "../app/modules/Appointments";
import AppointmentDetails from "../app/modules/Appointments/Components/AppointmentDetails";
import Specialities from "../app/modules/Specialties";
import Symptoms from "../app/modules/Symptoms";
import PrivateRoute from '../utils/PrivateRoute';
import PublicRoute from '../utils/PublicRoute';
import Login from "../app/pages/Login";
import Patients from "../app/modules/Patients";
import TotalEarnings from "../app/modules/TotalEarnings";
import Transactions from "../app/modules/Transactions";
import TransactionDetails from "../app/modules/Transactions/Components/TransactionDetails";
import TermsAndConditionPage from "../app/pages/TermsAndCondition";
import Setting from "../app/modules/Setting";
import NotificationViewer from "../app/modules/NotificationViewer";
import NotificationViewDetails from "../app/modules/NotificationViewer/Components/NotificationViewDetails";
import Countries from "../app/modules/Countries";
import States from "../app/modules/States";
import Cities from "../app/modules/Cities";
import MailPage from "../app/pages/Mails";
import Staff from "../app/modules/Staff";
import StaffEdit from "../app/modules/Staff/Form/Edit";
import Influencer from "../app/modules/Influencer/index";
import InfluencerEdit from "../app/modules/Influencer/Form/Edit";
import Videos from "../app/modules/Setting/Videos";
import NotificationSendPage from "../app/pages/NotificationSend";
import Video from "../app/modules/Setting/Video";

const Pages = (props) => {
  const {
    match: { path },
  } = props;
  return (
    <Switch>
          <Redirect to="/login"  from="/" exact /> 
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/providers" component={Providers} />
          <PrivateRoute exact path="/provider/edit/:id" component={ProviderEdit} />
          <PrivateRoute exact path="/appointments" component={Appointments} />
          <PrivateRoute exact path="/appointment/view/:id/:uuid" component={AppointmentDetails} />
          <PrivateRoute exact path="/specialities" component={Specialities} />
          <PrivateRoute exact path="/symptoms" component={Symptoms} />
          <PrivateRoute exact path="/patients" component={Patients} />
          <PrivateRoute exact path="/notifications" component={NotificationViewer} />
          <PrivateRoute exact path="/notifications/view/:id" component={NotificationViewDetails} />
          <PrivateRoute exact path="/notifications-send" component={NotificationSendPage} />
          <PrivateRoute exact path="/total-earnings" component={TotalEarnings} />
          <PrivateRoute exact path="/transactions" component={Transactions} />
          <PrivateRoute exact path="/transaction/view/:id" component={TransactionDetails} />
          <PrivateRoute exact path="/patient/edit/:id" component={PatientEdit} />
          <PrivateRoute exact path="/staff" component={Staff} />
          <PrivateRoute exact path="/staff/:staff_id" component={StaffEdit} />
          <PrivateRoute exact path="/influencer" component={Influencer} />
          <PrivateRoute exact path="/influencer/edit/:influencer_id" component={InfluencerEdit} />
          <PrivateRoute exact path="/terms-and-condition" component={TermsAndConditionPage} />
          <PrivateRoute exact path="/mails" component={MailPage} />
          <PrivateRoute exact path="/settings" component={Setting} />
          <PrivateRoute exact path="/videos" component={Video} />
          <PrivateRoute exact path="/countries" component={Countries} />
          <PrivateRoute exact path="/states" component={States} />
          <PrivateRoute exact path="/cities" component={Cities} />
          <PublicRoute restricted component={Login} path='/login' exact />
          <Redirect to="/error" /> 
    </Switch>
  );
};

export default Pages;
