import { Badge, message, Card, Col, Avatar, Row, Tooltip, Select, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { getStaffList, staffDelete } from './ducks/actions'
import { getSpecialitiesList } from '../Specialties/ducks/actions'
import { UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { getStatesList } from '../States/ducks/actions';
import AppointmentStatusTag from '../../atoms/AppointmentStatusTag';
import { useParams } from "react-router-dom";
import { getProvidersList } from '../Providers/ducks/actions';


const Staff = props => {

  const dispatch = useDispatch();
  const urlParams = useParams()

  const [rows, setRows] = useState([])
  const [providerListData, setProviderListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 10,
    provider_id: ''
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const staffList = useSelector(
    (state: any) => state?.staff?.staffList,
  );

  const staffDelete = useSelector(
    (state: any) => state?.staff?.staffDelete,
  );

  const providersList = useSelector(
    (state: any) => state.providers.providerList,
  );

  const userStatus = {
    'pending': 'blue',
    'approved': 'green',
    'disapproved': 'red'
  }

  let history = useHistory();

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      render: (text, record) => {
        return record?.full_name;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, record) => {
        return record?.email; // just for decoration
      },
    },
    {
      title: 'Provider',
      dataIndex: 'staff_provider.full_name',
      render: (text, record) => {
        return record?.staff_provider?.full_name; // just for decoration
      },
    },
    {
      title: 'Appointments',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (text, record) => {
        return <Avatar.Group
          maxCount={3}
          size="small"
          maxStyle={{ color: '#fff', backgroundColor: '#2f91be' }}>
          {record?.staff_bookings.length > 0 && record?.staff_bookings.map(function (item) {
            return <Tooltip title={item?.patient?.full_name} placement="top">
              <span onClick={() => viewAllAppointment(item, 'single')}><Avatar className="cursor-pointer" src={item?.patient?.avatar?.thumb} /></span>
            </Tooltip>
          })
          }
          {record?.staff_bookings.length > 0 &&
            <Tooltip title='View all' placement="top">
              <span onClick={() => viewAllAppointment(record, 'all')}><Avatar className="cursor-pointer" style={{ color: '#fff', backgroundColor: '#2f91be' }}> <EyeOutlined /> </Avatar></span>
            </Tooltip>}
        </Avatar.Group>

        return record?.speciality?.name; // just for decoration
      },
      responsive: ['lg', 'md'],
    },
    {
      title: 'Register at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        return moment.utc(record.created_at).format('llll'); // just for decoration
      }
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
        return <Tooltip placement="top" title={'Edit'}><Link to={`/staff/${record?.id}`} > <EditOutlined /></Link></Tooltip>
      }
    },
  ];

  const viewAllAppointment = (value, type) => {
    if (type == 'single') {
      history.push(`/appointment/view/${value.id}/${value.uuid}`);
    } else {
      history.push({
        pathname: `/appointments`,
        state: { staff_id: value.id }
      });
    }
  }

  useEffect(() => {
    dispatch(getProvidersList({
      pageSize: 100000,
      providerType:'practice'

    }));
  }, []);

  useEffect(() => {

    if (staffList?.data) {
      setPagination({
        ...pagination, ...{
          current: staffList?.current_page,
          total: staffList?.total,
          pageSize: staffList?.per_page
        }
      })
      setRows(staffList?.data)
      setLoading(false)
    }
    if (staffList?.response?.data?.message) {
      message.error(staffList?.response?.data?.message)
      setLoading(false)
    }
  }, [staffList])

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value.current,
      pageSize: value.pageSize,
      order: sorter.order,
      field: sorter.field,
    }
    setParams({ ...params, ...param })
  };

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeFirstDropDown = value => {
    let param = {
      speciality_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeDateRange = value => {
    let param = {
      page: 1,
      startDate: value?.[0].utc().format('YYYY-MM-DD'),
      endDate: value?.[1].utc().format('YYYY-MM-DD'),
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onDelete = (value) => {
    let param = {
      ids: value
    }
    setLoading(true)
    setOnDeleteSuccess(false)
    dispatch(staffDelete(param))
    setOnDeleteSuccess(true)
  }

  useEffect(() => {

    if (staffDelete?.data?.data) {
      dispatch(getStaffList({
        pageSize: pagination?.pageSize
      }));
      message.success(staffDelete?.message);
    }

  }, [staffDelete])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setLoading(true)
      dispatch(getStaffList(params))
    }
  }, [params])

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'staff',
      breadcrumbName: 'Staff',
    }
  ];


  const onChangeProvider = value => {
    let param = {
      provider_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }



  const MoreFilders = props => {

    const providersListOptions = [];
    providersList?.data?.map(function (item) {
      providersListOptions.push({
        label: item?.user?.full_name,
        value: item?.user?.id,
      });
    })

    return <>
      <Col span={4} sm={6}>
        <Select
          style={{ width: '100%' }}
          placeholder={'Select Provider'}
          allowClear
          value={params?.provider_id?params?.provider_id:null}
          options={providersListOptions}
          onChange={onChangeProvider}
        />
      </Col>
    </>
  }


  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Staff | ${pagination?.total}`}
            />
          </Card>
        </Col>
        <Col span={24} className="mt-1">
          <Card
            bordered={false}
            className="noicon-space"
          >
            <SosGrid
              pagination={pagination}
              loading={loading}
              columns={columns}
              onChangeGrid={onChangeGrid}
              dataSource={rows}
              onSearch={(value) => onSearch(value)}
              handelDateRange={onChangeDateRange}
              onDelete={onDelete}
              moreFilterProps={<MoreFilders />}
              onDeleteSuccess={onDeleteSuccess}
              rowKey={'user_id'}
            />
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Staff;
