import * as action_types from "./constants";

const specialities = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.SPECIALITIES_LIST: 
        return {
          ...state,
          specialitiesList: data,
        };
      default:
        return state;
    }

};

export default specialities;
