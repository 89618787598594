import React, { useState } from 'react';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Button, Card, Col, message, Row, Space, Spin } from 'antd';
import Form from 'antd/lib/form/Form';
import { useForm, useFieldArray } from "react-hook-form";
import { InputField, SelectField } from '../../atoms/FormElement';
import IsProduction from './Components/IsProduction';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsData, settingUpdate } from './ducks/actions';


const Video = props => {

  const [loading, setLoading] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const [dataField, setDataField] = useState({
    price_limit: 0
  });

  const dispatch = useDispatch();


  const settingData = useSelector(
    (state: any) => state.settings.settingsData?.data?.data?.data,
  );

  const settingUpdateData = useSelector(
    (state: any) => state.settings.settingsUpdate,
  );

  const { control, errors, setValue, reset, handleSubmit, watch, register, setError } = useForm({
    mode: 'onChange',
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "videos", // unique name for your Field Array
  });



  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'videos',
      breadcrumbName: 'Video Tutorial',
    }
  ];


  useEffect(() => {
    if (!!settingData) {
      setLoading(false)
      setLoadingVideo(false)
      console.log("settingData", settingData?.videos)
      setValue('videos', settingData?.videos); // ✅ performant
    }
  }, [settingData])

  useEffect(() => {
    if (settingUpdateData?.data?.status == 1) {
      setLoading(false)
      setLoadingVideo(false)
      message.success(settingUpdateData?.data?.message)
      console.log('settingUpdateData', settingUpdateData?.data?.message)
    } else if (settingUpdateData?.data?.status == 0) {
      console.log('settingUpdateData', settingUpdateData?.data)
      message.error(settingUpdateData?.data?.data?.data?.message)
    }
  }, [settingUpdateData])


  useEffect(() => {
    dispatch(getSettingsData())
  }, [])

  const onFinish = (value) => {
    setLoading(true)
    value.key = 'booking_price_limit',
      value.value = value.booking_price_limit
    dispatch(settingUpdate(value));

  }

  const onVideoFinish = (value) => {
    setLoadingVideo(true)
    value.key = 'videos',
      value.value = value.videos
    dispatch(settingUpdate(value));
  }
  // const getSettingsData = ()=>{
  //   setLoadingPriceLimit(true)
  //   let url = '/setting';
  //   const data  =  axios.get(url)
  //   .then(function (response) {
  //     setDataField({...dataField,...{price_limit:response?.data?.data?.data}})
  //     setLoadingPriceLimit(false)
  //   })
  //   .catch(function (error) {
  //     setLoadingPriceLimit(false)
  //     message.error(error?.response?.data?.message)
  //   });
  // }

  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Videos`}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={{ xs: 4, sm: 4, md: 4, lg: 4 }} className="mt-2" >
        <Col span={24} xs={24} md={24}>
          <Card
            bordered={false}
            className="noicon-space"
            title="Video Tutorial"
          >
            <Form layout="vertical" onFinish={handleSubmit(onVideoFinish)}>
              <Spin spinning={loadingVideo} >
                {fields.map((item, index) => (

                  <Row gutter={24} key={index}>
                    <Col span={6} xs={24} sm={24} md={6} xxl={6}>
                      <SelectField
                        isRequired={true}
                        fieldname={`videos.${index}.type`}
                        label="State"
                        control={control}
                        iProps={{ placeholder: 'Select For ' }}
                        rules={{ required: "Please select State" }}
                        valueGot={settingData?.videos?.[index]?.type}
                        setValue={setValue}
                        selectOption={[
                          { value: 'patient', label: 'Patient' },
                          { value: 'provider', label: 'Provider' },
                          { value: 'staff', label: 'Staff' },
                          { value: 'influencer', label: 'Influencer' },
                        ]}
                        validate={errors?.user?.state_id && "error"}
                        validMessage={errors?.[index]?.type && errors?.[index]?.type?.message}
                      />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={6} xxl={6}>
                      <InputField
                        isRequired={true}
                        fieldname={`videos.${index}.title`}
                        label="Enter video title"
                        control={control}
                        initValue=""
                        validate={errors?.[index]?.title && "error"}
                        valueGot={settingData?.videos?.[index]?.title}
                        fullwidth={true}
                        setValue={setValue}
                        validMessage={errors?.[index]?.title && errors?.[index]?.title?.message}
                      />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={12} xxl={12}>
                      <InputField
                        isRequired={true}
                        fieldname={`videos.${index}.url`}
                        label="Enter video url"
                        control={control}
                        initValue=""
                        validate={errors?.[index]?.title && "error"}
                        valueGot={settingData?.videos?.[index]?.url}
                        fullwidth={true}
                        setValue={setValue}
                        validMessage={errors?.[index]?.url && errors?.[index]?.url?.message}
                      />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={8} xxl={2} style={{ display: 'flex', alignItems: 'center',marginBottom: 30 }}>
                      <Button type="primary" color='danger' danger onClick={() => remove(index)} >Delete</Button>

                      {fields.length==(index+1)?<Button type="primary" color='danger' onClick={() => append({ title: "" })} >Add</Button>:''}
                    </Col>
                  </Row>

                ))}
                <br />
                <Button type="primary" htmlType="submit">Save</Button>
              </Spin>
            </Form>
          </Card>
        </Col>
      </Row>
    </DashboardLayout >
  );
};

export default Video;
