import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getNotificationsList = (param = {}) => {
  return async (dispatch) => {
    try {
      let url = "notification/list";
      const data = await axios.get(url, { params: param });
      dispatch({
        type: action_types.NOTIFICATION_LIST,
        data: data.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.NOTIFICATION_LIST,
        data: e,
      });
    }
  };
};

export const getNotificationDetails = (params) => {
  return async (dispatch) => {
    try {
      let url = "notification/view/" + params?.id;
      const data = await axios.get(url);
      dispatch({
        type: action_types.NOTIFICATION_VIEW,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.NOTIFICATION_VIEW,
        data: e?.response,
      });
    }
  };
};

export const getNotificationDelete = (params) => {
  return async (dispatch) => {
    try {
      let url = "notification/delete";
      const data = await axios.post(url, params);
      dispatch({
        type: action_types.NOTIFICATION_DELETE,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.NOTIFICATION_DELETE,
        data: e.response.data,
      });
    }
  };
};
