import React from 'react';
import { Button, Tag } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import Typography from 'antd/lib/typography';

const { Text } = Typography;
const AppointmentStatusTag = props => {

  const { label, value, closable, onClose, tagRender, isNoteShow } = props;

  const color = {
    "expired": "red",
    "scheduled": "blue",
    "completed": "green",
    "pending_approval": "cyan",
    "pending": "blue",
    "complete_request": "gold",
    "declined": "magenta",
    "cancel": "volcano",
    "reject": "orange",
    "success": "green",
    "error": "red",
    "authorized": "green",
    "hold_pending": "gold",
    "settled": "green",
    "held": "blue",
    "release_pending": "cyan",
    "released": "green",
    "refunded": "red",
    "submitted_for_settlement": "green",
    "suspended": "red",
    "online": "green",
    "offline": 'red',
    "approved": "green",
    "disapproved": "red",
    "active": "green",
    "true": "green",
    "false": "red",

  }

  const icon = {
    "hold_pending": <SyncOutlined spin />,
    "held": <SyncOutlined spin />,
    "release_pending": <SyncOutlined spin />,
  }
  const note = {
    "hold_pending": 'The transaction will be held in escrow once settled.',
    "held": 'The transaction has settled and is now held in escrow and eligible to be released.',
    "release_pending": 'The transaction being held in escrow will be released and disbursed shortly.',
    "released": 'The transaction that was being held in escrow has been released.',
  }
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  var statusName = props?.status?.replace(/_/g, " ");
  if (statusName == 'suspended') {
    statusName = 'Braintree Suspended'
  } else if (statusName == 'active') {
    statusName = 'Braintree Active'
  }
  else if (props.type =='admin' && statusName == 'pending') {
    statusName = 'Pending'
  }else if ( statusName == 'pending') {
    statusName = 'Pending Braintree'
  }

return (
  <>
    {
      tagRender ?
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
          color={color[value]}
          className={`appointment-tag appointment-${value}`}>
          {label}
        </Tag>
        :
        <>

          <Tag icon={icon[props.status]} color={color[props.status]} className={`appointment-tag appointment-${props.status}`}>{statusName}</Tag>
          {isNoteShow && props?.status == 'held' && <><Button onClick={() => props.onClick("release")} type="dashed" className="button-glow" size={'small'}>Payment Release</Button> </>}
          {isNoteShow && (props?.status == 'held') && <Button onClick={() => props.onClick('refund')} danger type="ghost" className="button-glow" size={'small'}>Payment Refund</Button>}
        </>
    }
    {isNoteShow && note[props.status] && <p className="mb-0"><Text><Text strong>Note:</Text>{note[props.status]}</Text></p>}
  </>
);
};

export default AppointmentStatusTag;
