import { Row, Col, Card, Button, Spin, message } from 'antd';
import React, { useState } from 'react';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useRef } from 'react';
import { useEffect } from 'react';

const TermsAndConditionPage = (props) => {

  const reactQuillRef = useRef(null);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(null);

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'terms-and-condition',
      breadcrumbName: 'Terms & Condition',
    }
  ];

  useEffect(() => {
    getTermsAndCondition();
  }, [])

  const saveTermsAndCondition = () => {
    setLoading(true)
    const editor = reactQuillRef?.current?.getEditor()
    const unprivilegedEditor = reactQuillRef?.current?.makeUnprivilegedEditor(editor);
    let url = '/terms-and-condition';
    const value = {
      content: unprivilegedEditor.getHTML()
    }
    const data = axios.put(url, value)
      .then(function (response) {
        console.log(response);
        message.success(response?.data?.message)
        setLoading(false)

      })
      .catch(function (error) {
        setLoading(false)
        console.log(error?.response);
        message.error(error?.response?.data?.message)
      });
  }

  const getTermsAndCondition = () => {
    setLoading(true)
    let url = '/terms-and-condition';
    const data = axios.get(url)
      .then(function (response) {
        console.log(response?.data?.data?.data)
        setValue(response?.data?.data?.data)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error?.response);
        message.error(error?.response?.data?.message)
      });
  }

  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Terms & Condition`}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} className="mt-1">
        <Col span={24}>
          <Spin spinning={loading} >
            <Card bordered={false} className="noicon-space" >
              <ReactQuill ref={reactQuillRef} theme="snow" value={value} className="rich-editor" onChange={setValue} />
              <Button type="primary" onClick={saveTermsAndCondition} htmlType="submit" className="mt-1" style={{ float: 'right' }}> Update</Button>
            </Card>
          </Spin>
        </Col>
      </Row>
    </DashboardLayout>
  )
}


export default TermsAndConditionPage;
