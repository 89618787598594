/* eslint-disable react/display-name */
import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Typography, Form, Button, Popover, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
// import { updateFormValues } from "./duck/actions";
import { setCookie } from "../../../utils/helper";
import LoginTemplate from "./LoginTemplate";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import axios from "../../../utils/axiosInceptor";
import moment from "moment";

const { Text, Paragraph } = Typography;
const defaultValues: any = {email:'',password:''};

export default () => {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginStatus1, setLoginStatus1] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let token = JSON.parse(window.localStorage.getItem("sos_admin"))?.token;


  const history = useHistory();

  React.useEffect(() => {
    token && history.push("/dashboard");
  }, []);

  const { control, handleSubmit, errors, setValue } = useForm({defaultValues});

  const onFinish = async (values) => {
    setLoginStatus(false);
    setLoading(true);
    const url = `/login`;

    const postJson = {
      email: values.email,
      password: values.password,
    };
 
    try {
      const {
        data: { data },
      } = await axios.post(url, postJson);
      await new Promise((resolve, reject) => {
        localStorage.setItem("sos_admin", JSON.stringify(data));
        localStorage.setItem("deviceId", uuidv4());
        history.push("/dashboard");
        resolve("");
      });

     // .setCookie("landlordUser", true, new Date(data?.exp).getDate());
     
    } catch (error) {
      console.log(error);
      setLoading(false);
      const { response } = error;
      if (response?.data?.message) {
        setErrorMessage(response?.data?.message);
        if (response?.data?.message.search("password") > 0) {
          setLoginStatus(true);
        } else {
          setLoginStatus1(true);
        }
      } else {
        setLoginStatus(false);
        setLoginStatus1(false);
        message.error("Invalid Attempt");
      }
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit(onFinish)}
          >
            <Popover
              placement="right"
              content={
                <Text type="danger">
                  <InfoCircleOutlined /> {errorMessage}
                </Text>
              }
              onVisibleChange={() => setLoginStatus1(false)}
              trigger="click"
              visible={loginStatus1}
            >
              <InputField
                fieldname="email"
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Email",
                  size: "large",
                  prefix: <UserOutlined className="site-form-item-icon" />,
                }}
                rules={{
                  required: "Please input email",
                }}
                validate={errors.username && "error"}
                validMessage={errors.username && errors.username.message}
              />
            </Popover>
            <Popover
              placement="right"
              content={
                <Text type="danger">
                  <InfoCircleOutlined /> {errorMessage}
                </Text>
              }
              onVisibleChange={() => setLoginStatus(false)}
              trigger="click"
              visible={loginStatus}
            >
              <InputPassword
                fieldname="password"
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Password",
                  size: "large",
                  prefix: <LockOutlined className="site-form-item-icon" />,
                }}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^\S*$/,
                    message: "Please enter correct password",
                  },
                  required: "Please enter password",
                }}
                validate={errors.password && "error"}
                validMessage={errors.password && errors.password.message}
              />
            </Popover>
            <Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              {/* <Link to="/forgot-password" className="login-form-forgot">
                Forgot password
              </Link> */}
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Paragraph className="copyright">Copyright © {moment().format('YYYY')}</Paragraph>
        </Col>
      </Row>
    </LoginTemplate>
  );
};
