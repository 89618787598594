import { Badge, message, Card, Col, Avatar, Row, Tooltip, Select, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { getProvidersList, providersDelete } from './ducks/actions'
import { getSpecialitiesList, resetState } from '../Specialties/ducks/actions'
import { UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { getStatesList } from '../States/ducks/actions';
import AppointmentStatusTag from '../../atoms/AppointmentStatusTag';
import axios from 'axios'

const dataSource = [];
for (let i = 0; i < 46; i++) {
  dataSource.push({
    key: i,
    name: `Edward King ${i}`,
    email: `bilalmalik${i}@gmai.com`,
    no_of_appointment: 1 + i,
    created_at: 'Tue 20 Mar, 2012 05:15Pm',
  });
}



const Providers = props => {

  const dispatch = useDispatch();
  const [rows, setRows] = useState([])
  const [specialitiesData, setSpecialitiesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 10,
    device: ''
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,

  })

  const providersList = useSelector(
    (state: any) => state.providers.providerList,
  );

  const providerDelete = useSelector(
    (state: any) => state.providers.providersDelete,
  );

  const specialitiesList = useSelector(
    (state: any) => state.specialities.specialitiesList?.data?.data,
  );

  const statesList = useSelector(
    (state: any) => state.states.statesList,
  );

  const userStatus = {
    'pending': 'blue',
    'approved': 'green',
    'disapproved': 'red'
  }

  let history = useHistory();

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      render: (text, record) => {

        let color = ''
        if (record?.user?.availability == 'online')
          color = '#52c31b'
        if (record?.user?.availability == 'offline')
          color = '#ff4d4f'
        if (record?.user?.availability == 'busy')
          color = 'yellow'

        // return <Badge.Ribbon   color={color} text={record?.user?.availability}>
        //           {record?.user?.full_name}
        //       </Badge.Ribbon>; // just for decoration

        return record?.user?.full_name;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text, record) => {
        return record?.user?.email; // just for decoration
      },
    },
    {
      title: 'Login / Register Device Type / App Version',
      dataIndex: 'device_type',
      render: (text, record) => {
        return record?.user?.device_type + ' / ' + record?.user?.reg_device_type + ' / ' + (record?.user?.app_version ? record?.user?.app_version : 0); // just for decoration
      },
    },
    {
      title: 'Phone No',
      dataIndex: 'phone_no',
      render: (text, record) => {
        return record?.user?.phone_no
      },
      responsive: ['lg', 'md'],
    },
    {
      title: 'Speciality',
      dataIndex: 'specialities',
      width: 150,
      render: (text, record) => {
        let _data: any = params;
        return record?.specialities?.map(item => <Tag color={(_data?.speciality_id && _data?.speciality_id == item.id) && "green"} style={{ marginBottom: '3px' }}>{item.name}</Tag>); // just for decoration
      },
      responsive: ['lg', 'md'],
    },
    {
      title: 'Appointments',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (text, record) => {
        return <Avatar.Group
          maxCount={3}
          size="small"
          maxStyle={{ color: '#fff', backgroundColor: '#2f91be' }}>
          {record?.bookings.length > 0 && record?.bookings.map(function (item) {
            return <Tooltip title={item?.patient?.full_name} placement="top">
              <span onClick={() => viewAllAppointment(item, 'single')}><Avatar className="cursor-pointer" src={item?.patient?.avatar?.thumb} /></span>
            </Tooltip>
          })
          }
          {record?.bookings.length > 0 &&
            <Tooltip title='View all' placement="top">
              <span onClick={() => viewAllAppointment(record, 'all')}><Avatar className="cursor-pointer" style={{ color: '#fff', backgroundColor: '#2f91be' }}> <EyeOutlined /> </Avatar></span>
            </Tooltip>}
        </Avatar.Group>

        return record?.speciality?.name; // just for decoration
      },
      responsive: ['lg', 'md'],
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {

        return <AppointmentStatusTag type={'admin'} status={record?.user?.status} />
      },
      responsive: ['lg', 'md'],
    },
    {
      title: 'Braintree Status',
      dataIndex: 'meta.0.value',
      key: 'braintree_status',
      render: (text, record) => {

        return <AppointmentStatusTag status={record?.user?.meta?.[0]?.value} />
      },
      responsive: ['lg', 'md'],
    },
    {
      title: 'Register at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        return moment.utc(record.created_at).format('llll'); // just for decoration
      }
    },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
        return <Tooltip placement="top" title={'Edit'}><Link to={`/provider/edit/${record?.id}`} > <EditOutlined /></Link></Tooltip>
      }
    },
  ];

  const viewAllAppointment = (value, type) => {
    if (type == 'single') {
      history.push(`/appointment/view/${value.id}/${value.uuid}`);
    } else {
      history.push({
        pathname: `/appointments`,
        state: { provider_id: value.user_id }
      });
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(getProvidersList({
      pageSize: pagination?.pageSize
    }));
    dispatch(getSpecialitiesList())
  }, []);


  useEffect(() => {
    let url = "provider/list";
    const data = axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired",5);
        history.push("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (specialitiesList?.data?.length > 0)
      setSpecialitiesData(specialitiesList?.data?.map(function (item) {
        return {
          label: item.name,
          value: item.id,
        };
      }))

  }, [specialitiesList])

  useEffect(() => {

    if (providersList?.data) {
      setPagination({
        ...pagination, ...{
          current: providersList?.current_page,
          total: providersList?.total,
          pageSize: providersList?.per_page
        }
      })
      setRows(providersList?.data)
      setLoading(false)
    }
    if (providersList?.response?.data?.message) {
      message.error(providersList?.response?.data?.message)
      setLoading(false)
    }
  }, [providersList])

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value.current,
      pageSize: value.pageSize,
      order: sorter.order,
      field: sorter.field,
    }
    setParams({ ...params, ...param })
  };

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeFirstDropDown = value => {
    let param = {
      speciality_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeDateRange = value => {
    let param = {
      page: 1,
      startDate: value?.[0].utc().format('YYYY-MM-DD'),
      endDate: value?.[1].utc().format('YYYY-MM-DD'),
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onDelete = (value) => {
    let param = {
      ids: value
    }
    setLoading(true)
    setOnDeleteSuccess(false)
    dispatch(providersDelete(param))
    setOnDeleteSuccess(true)
  }

  useEffect(() => {

    if (providerDelete?.data?.data) {
      dispatch(getProvidersList({
        pageSize: pagination?.pageSize
      }));

      message.success(providerDelete?.message);
      dispatch(resetState({
        type: 'PROVIDERS_DELETE',
        data: null
      }))
    }

  }, [providerDelete])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setLoading(true)
      dispatch(getProvidersList(params))
    }
  }, [params])

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'providers',
      breadcrumbName: 'Providers',
    }
  ];


  const onChangeDevice = value => {
    let param = {
      device: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }



  const MoreFilders = props => {

    const patientsListOptions = [];
    patientsListOptions.push({
      label: 'Iphone',
      value: 'iphone',
    }, {
      label: 'Android',
      value: 'android',
    });
    return <>
      <Col span={4} sm={6}>
        <Select
          style={{ width: '100%' }}
          placeholder={'Select Device'}
          allowClear
          value={params?.device}
          options={patientsListOptions}
          onChange={onChangeDevice}
        />
      </Col>
    </>
  }


  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Provider | ${pagination?.total}`}
            />
          </Card>
        </Col>
        <Col span={24} className="mt-1">
          <Card
            bordered={false}
            className="noicon-space"
          >
            <SosGrid
              pagination={pagination}
              loading={loading}
              columns={columns}
              onChangeGrid={onChangeGrid}
              dataSource={rows}
              setFirstDropDownOption={specialitiesData}
              setFirstDropDownPlaceHolder={'Select a specialities'}
              onChangeFirstDropDown={onChangeFirstDropDown}
              onSearch={(value) => onSearch(value)}
              handelDateRange={onChangeDateRange}
              onDelete={onDelete}
              moreFilterProps={<MoreFilders />}
              onDeleteSuccess={onDeleteSuccess}
              setOnDeleteSuccess={setOnDeleteSuccess}
              rowKey={'user_id'}
              isBulkDelete={true}
            />
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Providers;
