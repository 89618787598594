import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { getSpecialitiesList } from '../Specialties/ducks/actions'
import { UserOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { getStatesList } from '../States/ducks/actions';
import { getNotificationDelete, getNotificationsList } from './ducks/actions';
import axios from '../../../utils/axiosInceptor';
const dataSource = [];
for (let i = 0; i < 46; i++) {
  dataSource.push({
    key: i,
    name: `Edward King ${i}`,
    email: `bilalmalik${i}@gmai.com`,
    no_of_appointment: 1 + i,
    created_at: 'Tue 20 Mar, 2012 05:15Pm',
  });
}



const NotificationViewer = props => {

  const dispatch = useDispatch();
  const [rows, setRows] = useState([])
  const [specialitiesData, setSpecialitiesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 10,
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

  const notificationViewersList = useSelector(
    (state: any) => state.notifications.notificationViewerList?.data?.data,
  );

  const notificationsDataDelete = useSelector(
    (state: any) => state.notifications.notificationsDelete,
  );

  let history = useHistory();
  useEffect(() => {
    let url = "provider/list";
    const data = axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired", 5);
        history.push("/login");
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true)
    dispatch(getNotificationsList());
  }, []);



  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver_id',
      render: (text, record) => {
        return `${record?.receiver?.full_name} (${record?.receiver?.type})`;
      },
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      render: (text, record) => {
        if (record?.sender?.full_name) {
          return `${record?.sender?.full_name} (${record?.sender?.type})`;
        } else {
          return 'Admin'
        }
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      render: (text, record) => {
        return moment(record.created_at).format('llll');
      },
    },
    // {
    //   title: 'Created at',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   render: (text, record) => {
    //     return moment.utc(record.created_at).format('llll'); // just for decoration
    //   }
    // },
    {
      title: 'Actions',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
        return <Tooltip placement="top" title={'Edit'}><Link to={`/notifications/view/${record?.id}`} > <EditOutlined /></Link></Tooltip>
      }
    },
  ];


  useEffect(() => {
    if (notificationViewersList?.data) {
      setPagination({
        ...pagination, ...{
          current: notificationViewersList?.current_page,
          total: notificationViewersList?.total,
          pageSize: notificationViewersList?.per_page
        }
      })
      setRows(notificationViewersList?.data)
      setLoading(false)
    }

    if (notificationViewersList?.length == 0) {
      setLoading(false)
    }

    if (notificationViewersList?.response?.data?.message) {
      message.error(notificationViewersList?.response?.data?.message)
      setLoading(false)
    }
  }, [notificationViewersList])

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value.current,
      pageSize: value.pageSize,
      order: sorter.order,
      field: sorter.field,
    }
    setParams({ ...params, ...param })
  };

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  // const onChangeFirstDropDown = value =>{
  //     let param = {
  //       speciality_id:value,
  //       page:1,
  //       pageSize:pagination?.pageSize
  //     }
  //     setParams({...params,...param})
  // }

  const onChangeDateRange = value => {
    console.log(value)
    let param = {
      page: 1,
      startDate: value?.[0].utc().format('YYYY-MM-DD'),
      endDate: value?.[1].utc().format('YYYY-MM-DD'),
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onDelete = (value) => {
    let param = {
      ids: value
    }
    setLoading(true)
    setOnDeleteSuccess(false)
    dispatch(getNotificationDelete(param))
    setOnDeleteSuccess(true)
  }

  useEffect(() => {

    console.log("notificationsDataDelete", notificationsDataDelete)
    if (notificationsDataDelete?.data?.data) {
      dispatch(getNotificationsList({
        pageSize: pagination?.pageSize
      }));
      message.success(notificationsDataDelete?.message);
    }
  }, [notificationsDataDelete])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setLoading(true)
      dispatch(getNotificationsList(params))
    }
  }, [params])

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'notificationViewer',
      breadcrumbName: 'NotificationViewer',
    }
  ];



  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Notifications | ${pagination?.total}`}
            />
          </Card>
        </Col>
        <Col span={24} className="mt-1">
          <Card
            bordered={false}
            className="noicon-space"
          >
            <SosGrid
              pagination={pagination}
              loading={loading}
              columns={columns}
              onChangeGrid={onChangeGrid}
              dataSource={rows}
              setFirstDropDownOption={specialitiesData}
              setFirstDropDownPlaceHolder={'Select a specialities'}
              // onChangeFirstDropDown={onChangeFirstDropDown}
              onSearch={(value) => onSearch(value)}
              handelDateRange={onChangeDateRange}
              onDelete={onDelete}
              onDeleteSuccess={onDeleteSuccess}
              rowKey={'id'}
              isBulkDelete={true}
            />
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default NotificationViewer;
