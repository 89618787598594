import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getStaffList = (param={}) => {
  return async (dispatch) => {
    try {
      let url = 'staff/list';
      const data  = await axios.get(url,{params:param});
      dispatch({
        type: action_types.STAFF_LIST,
        data: data.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.STAFF_LIST,
        data: e,
      });
    }
  };
};


export const staffDelete = (param) => {
  return async (dispatch) => {
    try {
      let url = 'staff/delete';
      const data  = await axios.post(url,param);
      dispatch({
        type: action_types.STAFF_DELETE,
        data: data?.data
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getStaffEdit = (id) => {
  return async (dispatch) => {
    try {
      let url = 'staff/edit/'+id;
      const data  = await axios.get(url);
      dispatch({
        type: action_types.STAFF_EDIT,
        data: data?.data
      });
    } catch (e) {
      dispatch({
        type: action_types.STAFF_EDIT,
        data: e?.response
      });
    }
  };
};

export const staffUpdate = (id,params=null) => {
  return async (dispatch) => {
    try {
      let url = 'staff/update/'+id;
      const data  = await axios.put(url,params);
      dispatch({
        type: action_types.STAFF_UPDATE,
        data: data
      });
    } catch (e) {
      dispatch({
        type: action_types.STAFF_UPDATE,
        data: e
      });
    }
  };
};


export const staffStatusUpdate = (id,params=null) => {
  return async (dispatch) => {
    try {
      let url = 'staff/status/update/'+id;
      const data  = await axios.put(url,params);
      dispatch({
        type: action_types.STAFF_STATUS_UPDATE,
        data: data
      });
    } catch (e) {
      dispatch({
        type: action_types.STAFF_STATUS_UPDATE,
        data: e
      });
    }
  };
};


