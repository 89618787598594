import * as action_types from "./constants";

const appointments = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.TRANSACTIONS_LIST: 
        return {
          ...state,
          transactionList: data,
        };
      case action_types.TRANSACTION_DETAILS: 
        return {
          ...state,
          transactionDetails: data,
        };
      case action_types.PROVIDERS_EDIT: 
        return {
          ...state,
          providerEdit: data,
        };
      default:
        return state;
    }

};

export default appointments;
