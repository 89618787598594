
var url = null;
if(location.protocol=='http:'){

    url  = "https://adminapi.sosdoctorhousecall.com/";

}
else{

     url  = "https://adminapi.sosdoctorhousecall.com/";
     
}


export const BASE_URL = url;

