import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getAppointmentsList = (param = {}) => {
  return async (dispatch) => {
    try {
      let url = "appointments/list";
      const data = await axios.get(url, { params: param });
      dispatch({
        type: action_types.APPOINTMENTS_LIST,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAppointmentDetails = (param = {}) => {
  return async (dispatch) => {
    try {
      let url = "appointments/view";
      const data = await axios.get(url, { params: param });
      dispatch({
        type: action_types.APPOINTMENT_DETAILS,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const appointmentsDelete = (param) => {
  return async (dispatch) => {
    try {
      let url = "appointments/delete";
      const data = await axios.post(url, param);
      dispatch({
        type: action_types.APPOINTMENT_DELETE,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.APPOINTMENT_DELETE,
        data: e.response.data,
      });
    }
  };
};

export const bookingAddReview = (param) => {
  return async (dispatch) => {
    try {
      let url = "appointments/review/add";
      const data = await axios.post(url, param);
      dispatch({
        type: action_types.APPOINTMENT_ADD_REVIEW,
        data: data?.data,
      });
    } catch (e) {
      dispatch({
        type: action_types.APPOINTMENT_ADD_REVIEW,
        data: e.response.data,
      });
    }
  };
};

// export const getProviderEdit = (id) => {
//   return async (dispatch) => {
//     try {
//       let url = 'provider/edit/'+id;
//       const data  = await axios.get(url);
//       dispatch({
//         type: action_types.PROVIDERS_EDIT,
//         data: data?.data
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };
