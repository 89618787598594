import React, { useState } from 'react';
import { Row, Col, Card, Button, Spin, Select, message, Input } from 'antd';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import Form from 'antd/lib/form/Form';
import { InputField, SelectField } from '../../atoms/FormElement';

const MailPage = (props) => {

  const reactQuillRef = useRef(null);
  const [valueEditor, setValueEditor] = useState('');
  const [loading, setLoading] = useState(null);
  const [device, setDevice] = useState(null);


  const { control, errors, reset, handleSubmit, setValue, getValues, register, setError } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    register("content", {
      required: true
    })
  },[])
  useEffect(()=>{
    console.log("errors",errors)
  },[errors])

  useEffect(()=>{
    const editor = reactQuillRef?.current?.getEditor()
    const unprivilegedEditor = reactQuillRef?.current?.makeUnprivilegedEditor(editor);
    setValue('content',unprivilegedEditor.getText())
  },[valueEditor])

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'mails',
      breadcrumbName: 'Mail',
    }
  ];

  const onFinish = (data:any) => {
    setLoading(true)
    const editor = reactQuillRef?.current?.getEditor()
    const unprivilegedEditor = reactQuillRef?.current?.makeUnprivilegedEditor(editor);
    let url = '/notification/mail/send';

    const value = {
      content: unprivilegedEditor.getHTML(),
      device: getValues('device'),
      subject:getValues('subject')
    }
      axios.post(url, value)
      .then(function (response) {
        message.success(response?.data?.message)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        message.error(error?.response?.data?.message)
        message.warning('In Progress')
      });
  }


  return (
    <DashboardLayout>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
          <Col span={24}>
            <Card
              bordered={false}
              className="noicon-space"
            >
              <PageHeaderSection
                routes={routes}
                title={`Mails`}
              />
            </Card>
          </Col>
        </Row>
        <Card bordered={false} className="noicon-space mt-1">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
            <Col span={4} sm={6}>
              <SelectField
                isRequired={true}
                fieldname="device"
                label="State"
                control={control}
                iProps={{ placeholder: 'Select Device' }}
                rules={{ required: "Please select Device" }}
                setValue={setValue}
                selectOption={[
                  {
                    label: 'Iphone',
                    value: 'iphone',
                  }, {
                    label: 'Android',
                    value: 'android',
                  }
                ]}
                validate={errors?.device && "error"}
                validMessage={errors?.device && errors?.device?.message}
              />

            </Col>
            <Col span={4} sm={8}>
              <InputField
                isRequired={true}
                fieldname="subject"
                label="Subject"
                control={control}
                rules={{
                  required: "Please enter Subject",
                }}
                iProps={{ placeholder: "Subject" }}
                initValue=""
                validate={errors?.subject && "error"}
                setValue={setValue}
                validMessage={errors?.subject && errors?.subject?.message}
              />
            </Col>
            <Col span={24} className="mt-1">
              <Spin spinning={loading} >
                <ReactQuill
                  ref={reactQuillRef}
                  modules={
                    {
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction

                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],

                        ['clean']                                         // remove formatting button
                      ]
                    }
                  }
                  theme="snow"
                  value={valueEditor} className="rich-editor" onChange={setValueEditor} />
                  {errors?.content}
                <Button type="primary" htmlType="submit" className="mt-1" style={{ float: 'right' }}> Send</Button>
              </Spin>
            </Col>
          </Row>
        </Card>
      </Form>
    </DashboardLayout>
  )
};

export default MailPage;
