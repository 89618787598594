import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Tabs,
  Select,
  Card,
  Typography,
  Spin,
  Statistic
} from "antd";
import Sidebar from "../../molecules/Sidebar";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import { Area } from '@ant-design/charts';
import { useDispatch, useSelector } from 'react-redux';
import { getProvidersEarning, getTotalEarnings } from "./duck/actions";
import moment from "moment";
import { template } from "@babel/core";
import SosGrid from '../../molecules/SosGrid';
import { Link } from "react-router-dom";

const { Option } = Select;

const { Meta } = Card;
const { Content } = Layout;
const { TabPane } = Tabs;
const { Title, Text } = Typography;

const monthData = [
  { month: 'Jan', value: 3 },
  { month: 'Feb', value: 4 },
  { month: 'Mar', value: 3.5 },
  { month: 'Apr', value: 5 },
  { month: 'May', value: 4.9 },
  { month: 'Jun', value: 6 },
  { month: 'July', value: 7 },
  { month: 'Aug', value: 9 }
];

const TotalEarnings = () => {

  const [data, setData] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingProvider, setLoadingProvider] = useState(true);
  const [currentYear, setCurrentYear] = useState(moment().year());

  const totalEarningsData = useSelector(
    (state: any) => state?.totalEarnings?.totalEarningsData,
  );

  const providerTotalEarningsData = useSelector(
    (state: any) => state?.totalEarnings?.providerTotalEarningsData,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotalEarnings());
    dispatch(getProvidersEarning());
  }, []);

  useEffect(() => {
    if (totalEarningsData?.data?.data) {
      setData(totalEarningsData?.data?.data)
      setLoading(false)
    }

  }, [totalEarningsData])

  useEffect(() => {
    if (providerTotalEarningsData?.data?.data) {
      setProviderData(providerTotalEarningsData?.data?.data)
      setLoadingProvider(false)
    }
  }, [providerTotalEarningsData])

  const handleChangeYear = (value) => {
    console.log(value)
    setLoading(true)
    dispatch(getTotalEarnings({
      year: value
    }))
    setCurrentYear(value)
  }

  const monthConfig = {
    data: data?.yearlyIncome ? data?.yearlyIncome : [],
    height: 400,
    xField: 'month',
    yField: 'amount',
    point: {
      size: 5,
      shape: 'diamond',
    },
  };



  const YearDropDown = () => {
    return <Select style={{ width: 200 }} onChange={handleChangeYear} value={data?.yearList.find(i => i.year == currentYear) ? currentYear : data?.yearList?.[1]?.year}>
    {
        data?.yearList?.map((item, index) => {
          return <Option key={index} value={item.year} >{item.year}</Option>
        })
      }
    </Select>;
  }

  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className="layout-space">
          <Content className="main-content">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} className={'mt-1'}>
              <Col span={18} xxl={18} xl={24}>
                <Spin spinning={loading}>
                  <Card
                    title="Total Amount"
                    extra={<YearDropDown />}
                    actions={[
                      <Title level={3}>Total Amount: ${data && data?.yearlyIncome?.map((a, b) => a.amount).reduce((a, b) => a + b, 0)}</Title>,
                    ]}
                  >
                    <Area  {...monthConfig} />
                  </Card>
                </Spin>
              </Col>

              <Col span={6} xxl={6} xl={24}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                  <Col span={24} md={8} xl={8} xxl={24}>
                    <Card
                      bordered={false}
                      className="noicon-space card-statistic"
                    >
                      <Title level={3}>Total Amount</Title>
                      <Statistic title="" valueStyle={{ color: "#fff" }} value={data?.totalAmount && '$' + data?.totalAmount.toFixed(2)} />
                    </Card>
                  </Col>
                  <Col span={24} md={8} xl={8} xxl={24} className={'mt-1'}>
                    <Card
                      bordered={false}
                      className="noicon-space card-statistic"
                    >
                      <Title level={3}>Paid Amount</Title>
                      <Statistic title="" valueStyle={{ color: "#fff" }} value={data?.paidAmount && '$' + data?.paidAmount.toFixed(2)} />
                    </Card>
                  </Col>
                  <Col span={24} md={8} xl={8} xxl={24} className={'mt-1'}>
                    <Card
                      bordered={false}
                      className="noicon-space card-statistic"
                    >
                      <Title level={3}>Pending Amount</Title>
                      <Statistic title="" valueStyle={{ color: "#fff" }} value={data?.pendingAmount && '$' + data?.pendingAmount.toFixed(2)} />
                    </Card>
                  </Col>
                  <Col span={24} md={8} xl={8} xxl={24} className={'mt-1'}>
                    <Card
                      bordered={false}
                      className="noicon-space card-statistic"
                    >
                      <Title level={3}>Total SOS Income</Title>
                      <Statistic title="" valueStyle={{ color: "#fff" }} value={data?.totalSosInCome && '$' + data?.totalSosInCome.toFixed(2)} />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} className={'mt-1'}>
              <Col span={24}>
                <Spin spinning={loadingProvider}>
                  <Card
                    bordered={false}
                    className="noicon-space"
                    title=" Providers"
                  >
                    <SosGrid
                      columns={[
                        {
                          title: 'Provider Name',
                          dataIndex: 'provider_name',
                          key: 'provider_name',
                        },
                        {
                          title: 'Total Amount',
                          dataIndex: 'amount',
                          key: 'amount',
                          render: (value, row, index) => {
                            return '$' + value.toFixed(2)
                          }
                        },
                        {
                          title: 'Provider Amount',
                          dataIndex: 'provider_amount',
                          key: 'provider_amount',
                          render: (value, row, index) => {
                            return '$' + value.toFixed(2)
                          }
                        },
                        {
                          title: 'SOS Amount',
                          dataIndex: 'sos_amount',
                          key: 'sos_amount',
                          render: (value, row, index) => {
                            return '$' + value.toFixed(2)
                          }
                        },
                        {
                          title: 'View Booking',
                          dataIndex: 'booking_id',
                          key: 'booking_id',
                          render: (value, row, index) => {
                            if (!!value)
                              return <Link to={'/appointment/view/' + value + '/123123'}>View Booking</Link>
                          }
                        }
                      ]}
                      rowSelection={{ checkStrictly: false }}
                      dataSource={providerData}
                      rowKey={'key'}
                    />
                  </Card>
                </Spin>
              </Col>
            </Row>

            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default TotalEarnings;
