import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getTransactionsList = (param={}) => {
  return async (dispatch) => {
    try {
      let url = 'transactions/list';
      const data  = await axios.get(url,{params:param});
      dispatch({
        type: action_types.TRANSACTIONS_LIST,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTransactionDetails = (param={}) => {
  return async (dispatch) => {
    try {
      let url = 'transactions/view';
      const data  = await axios.get(url,{params:param});
      dispatch({
        type: action_types.TRANSACTION_DETAILS,
        data: data.data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};


// export const providersDelete = (param) => {
//   return async (dispatch) => {
//     try {
//       let url = 'provider/delete';
//       const data  = await axios.post(url,param);
//       dispatch({
//         type: action_types.PROVIDERS_DELETE,
//         data: data?.data
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };

// export const getProviderEdit = (id) => {
//   return async (dispatch) => {
//     try {
//       let url = 'provider/edit/'+id;
//       const data  = await axios.get(url);
//       dispatch({
//         type: action_types.PROVIDERS_EDIT,
//         data: data?.data
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };