import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, PageHeader, Descriptions, Spin, Switch, Tag, Rate } from 'antd';
import React, { useEffect, useState } from 'react';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useParams
} from "react-router-dom";

import DashboardLayout from '../../../molecules/DashboardLayout';
import PageHeaderSection from '../../../molecules/PageHeaderSection';
import Form from 'antd/lib/form/Form';
import { useForm } from "react-hook-form";
import { DateField, InputField, MaskInputField, SelectField, TextAreaField } from '../../../atoms/FormElement';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientEdit, patientUpdate } from '../ducks/actions'
import PatientEditLeftSideBar from '../../../molecules/PatientEditLeftSideBar';
import moment from 'moment';
import { getStatesList } from '../../States/ducks/actions';
import { getCitiesList } from '../../Cities/ducks/actions';
//import { getSpecialitiesList } from '../../Specialties/ducks/actions';
import { BASE_URL } from '../../../../configs/constants';
import { resetState } from '../../Specialties/ducks/actions';
import SosGrid from '../../../molecules/SosGrid';
import AppointmentStatusTag from '../../../atoms/AppointmentStatusTag';
import Axios from 'axios';
import {  useHistory } from "react-router-dom";
import axios from '../../../../utils/axiosInceptor';


const PatientEdit = props => {
    const params = useParams()



    const dispatch = useDispatch();
    const [patientData, setPatientData] = useState({
        user: null,
        created_at: null,
        user_id: null,
    });
    const [loading, setLoading] = useState(null);

    const { control, errors, reset, handleSubmit, setValue, watch, register, setError } = useForm({
        mode: 'onChange',
    });


    const patientEditData = useSelector(
        (state: any) => state.patients.patientEdit,
    );

    let url = window?.location?.search?.slice(1);
    let params_ = new URLSearchParams(url);
    var is_login = (params_?.getAll('logins')?.[0])

    const patientUpdateData = useSelector(
        (state: any) => state.patients.patientUpdate,
    );

    //   const patientStatusUpdateData = useSelector(
    //     (state:any) => state.patients.patientStatusUpdate,
    //   );


    const statesList = useSelector(
        (state: any) => state.states.statesList,
    );

    const citiesList = useSelector(
        (state: any) => state.cities.citiesList,
    );

    // const specialitiesList = useSelector(
    //     (state:any) => state.specialities.specialitiesList?.data?.data?.data,
    // );

    const allStates = useSelector(
        (state: any) => state,
    );


    useEffect(() => {

    }, [patientUpdateData])




    useEffect(() => {
        setLoading(true)
        dispatch(getPatientEdit(params?.id))
        dispatch(getStatesList({
            pageSize: 1000
        }, BASE_URL + 'api/list/states'))
    }, [])


    useEffect(() => {
        if (patientEditData?.data?.data) {
            setPatientData(patientEditData?.data?.data)
            dispatch(getCitiesList({
                pageSize: 1000,
                state_id: patientEditData?.data?.data?.user?.state_id
            }, BASE_URL + 'api/list/cities'))
            setLoading(false)
        }
    }, [patientEditData])

    let history = useHistory();
    useEffect(() => {
      let url = "provider/list";
      const data = axios.get(url).then(() => { }).catch((e) => {
        if (e.response.data.message == "Unauthenticated") {
          localStorage.removeItem("sos_admin");
          message.error("Your token has been expired", 5);
          history.push("/login");
        }
      });
    }, []);


    const routes = [
        {
            path: '/dashboard',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '/patients',
            breadcrumbName: 'patient',
        },
        {
            path: 'id',
            breadcrumbName: params.id
        }
    ];

    const getCitiesByStateId = (values) => {
        setValue("user.city_id", null)
        dispatch(getCitiesList({
            pageSize: 1000,
            state_id: values
        }, BASE_URL + 'api/list/cities'))

    }

    // const provideStatusChange = (value)=>{
    //     setLoading(false)
    //     setLoading(true)
    //     let param = {
    //         status:value?'approved':'disapproved'
    //     }
    //     dispatch(patientStatusUpdate(params?.id,param));
    // }

    // useEffect(()=>{
    //     if(patientStatusUpdateData?.data?.message){
    //         setLoading(false)
    //         dispatch(getPatientEdit(params?.id))
    //         message.success(patientStatusUpdateData?.data?.message)
    //     }
    // },[patientStatusUpdateData])

    useEffect(() => {
        if (patientUpdateData?.data?.status) {
            message.success(patientUpdateData?.data?.message)
            dispatch(getPatientEdit(params?.id))
            dispatch(resetState({
                type: 'PATIENT_UPDATE',
                data: null
            }))

        } else if (patientUpdateData?.data) {
            message.error(patientUpdateData?.data?.message)
        }
    }, [patientUpdateData])

    const onFinish = async (values) => {
        setLoading(false)
        setLoading(true)
        values.email = null;
        values.user.dob = moment(values.user.dob).format('YYYY-MM-DD')
        dispatch(patientUpdate(params?.id, values));
    }

    return <DashboardLayout>

        <Spin spinning={loading} >


            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
                <Col span={24}>
                    <Card bordered={false} className="noicon-space" >
                        <PageHeaderSection
                            routes={routes}
                            title={patientData?.user?.full_name}
                            extra={[
                                <Button key="1" type="primary" >
                                    <Link to={{
                                        pathname: `/appointments`,
                                        state: { patient_id: patientData?.user_id }
                                    }}>
                                        Appointment
                                    </Link>
                                </Button>,
                            ]}
                        >
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Registerd At">{moment.utc(patientData?.created_at).format("LLLL")}</Descriptions.Item>
                                <Descriptions.Item label="Referral Code">{patientData?.user?.refer_code}</Descriptions.Item>
                                <Descriptions.Item label="Referral Person">{patientData?.user?.refer_person}</Descriptions.Item>
                                <Descriptions.Item label="Register With" className={"page-header-reg-with"}>
                                    {patientData?.user?.social_type == 'gmail' ? <GoogleOutlined /> : ''}
                                    {patientData?.user?.social_type == 'facebook' ? <FacebookOutlined /> : ''}
                                    {patientData?.user?.social_type}
                                </Descriptions.Item>
                            </Descriptions>
                        </PageHeaderSection>
                    </Card>
                </Col>
            </Row>

            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>
                    <Col span={6} xs={24} sm={24} md={6} xxl={6}>
                        <PatientEditLeftSideBar data={patientData} />
                    </Col>
                    <Col span={6} xs={24} sm={24} md={18} xxl={18}>
                        <Card bordered={false} className="noicon-space mt-1">
                            <Row gutter={24}>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="user.first_name"
                                        label="First Name"
                                        control={control}
                                        rules={{
                                            required: "Please enter First name",
                                        }}
                                        iProps={{ placeholder: "First Name", maxLength: "50" }}
                                        initValue=""
                                        validate={errors?.user?.first_name && "error"}
                                        valueGot={patientData?.user?.first_name}
                                        setValue={setValue}
                                        validMessage={errors?.user?.first_name && errors?.user?.first_name?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="user.last_name"
                                        label="Last Name"
                                        control={control}
                                        rules={{
                                            required: "Please enter Last name",
                                        }}
                                        iProps={{ placeholder: "Last Name", maxLength: "50" }}
                                        initValue=""
                                        validate={errors?.user?.last_name && "error"}
                                        valueGot={patientData?.user?.last_name}
                                        setValue={setValue}
                                        validMessage={errors?.user?.last_name && errors?.user?.last_name.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="email"
                                        label="Email"
                                        control={control}
                                        iProps={{ disabled: true, readOnly: true }}
                                        valueGot={patientData?.user?.email}
                                        setValue={setValue}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <DateField
                                        isRequired={true}
                                        fieldname="user.dob"
                                        label="Date of Birth"
                                        control={control}
                                        rules={{
                                            required: "Please enter Date of Birth",
                                        }}
                                        iProps={{ placeholder: "Date of Birth", maxLength: "50" }}
                                        initValue=""
                                        valueGot={patientData?.user?.dob && moment(patientData?.user?.dob, 'YYYY-MM-DD')}
                                        setValue={setValue}
                                        validate={errors?.user?.dob && "error"}
                                        validMessage={errors?.user?.dob && errors?.user?.dob?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <MaskInputField
                                        isRequired={true}
                                        fieldname="user.phone_no"
                                        label="Phone No"
                                        control={control}
                                        rules={{
                                            required: "Please enter Phone no.",
                                            pattern: {
                                                value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/i,
                                                message: "Please enter valid Phone no."
                                            }
                                        }}
                                        iProps={{
                                            placeholder: "(111) 111-1111",
                                            mask: "(111) 111-1111"
                                        }}
                                        initValue=""
                                        valueGot={patientData?.user?.phone_no && patientData?.user?.phone_no}
                                        setValue={setValue}
                                        validate={errors?.user?.phone_no && "error"}
                                        validMessage={errors?.user?.phone_no && errors?.user?.phone_no?.message}
                                    />
                                </Col>

                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <SelectField
                                        isRequired={true}
                                        fieldname="user.state_id"
                                        label="State"
                                        control={control}
                                        iProps={{ placeholder: 'Select State' }}
                                        rules={{ required: "Please select State" }}
                                        valueGot={patientData?.user?.state_id}
                                        setValue={setValue}
                                        selectOption={statesList?.data?.data.map((item, index) => {
                                            return { value: item.id, label: item.name }
                                        })}
                                        onChange={getCitiesByStateId}
                                        validate={errors?.user?.state_id && "error"}
                                        validMessage={errors?.user?.state_id && errors?.user?.state_id?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <SelectField
                                        isRequired={true}
                                        fieldname="user.city_id"
                                        label="City"
                                        control={control}
                                        iProps={{ placeholder: 'Select City' }}
                                        rules={{ required: "Please select City" }}
                                        valueGot={patientData?.user?.city_id}
                                        setValue={setValue}
                                        selectOption={citiesList?.data?.data.map((item, index) => {
                                            return { value: item.id, label: item.name }
                                        })}
                                        validate={errors?.user?.city_id && "error"}
                                        validMessage={errors?.user?.city_id && errors?.user?.city_id?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <MaskInputField
                                        isRequired={false}
                                        fieldname="user.zip_code"
                                        label="Zip Code"
                                        control={control}
                                        rules={{
                                            minLength: { value: 5, message: "Please enter valid zip code, 5 digits required" },
                                            maxLength: { value: 5, message: "Please enter valid zip code, 5 digits required" },
                                            pattern: {
                                                value: /[0-9]{5}/,
                                                message: 'Please enter valid zip code.' // JS only: <p>error message</p> TS only support string
                                            }
                                        }}
                                        iProps={{
                                            placeholder: "1111",
                                            maxLength: "5",
                                            mask: "11111"
                                        }}
                                        initValue=""
                                        valueGot={patientData?.user?.zip_code}
                                        setValue={setValue}
                                        validate={errors?.user?.zip_code && "error"}
                                        validMessage={errors?.user?.zip_code && errors?.user?.zip_code?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={8} xxl={6}>
                                    <InputField
                                        isRequired={true}
                                        fieldname="user.wallet_amount"
                                        label="Wallet"
                                        control={control}
                                        valueGot={patientData?.user?.wallet_amount}
                                        setValue={setValue}
                                        rules={{
                                            required: "Please enter wallet amount.",
                                        }}
                                        validMessage={errors?.user?.wallet_amount && errors?.user?.wallet_amount?.message}
                                    />
                                </Col>
                                <Col span={6} xs={24} sm={24} md={24} xxl={24}>
                                    <TextAreaField
                                        isRequired={false}
                                        fieldname="address"
                                        label="Address"
                                        control={control}
                                        iProps={{ disabled: true, readOnly: true }}
                                        initValue=""
                                        valueGot={patientData?.user?.address}
                                        setValue={setValue}
                                        validate={errors?.user?.address && "error"}
                                        validMessage={errors?.user?.address && errors?.user?.address?.message}
                                    />
                                </Col>

                                {/* <Col span={6} xs={24} sm={24}  md={8} xxl={6}>
                                <SelectField
                                    isRequired={true}
                                    fieldname="patient.speciality_id"
                                    label="Speciality"
                                    control={control}
                                    iProps={{ placeholder: 'Select Speciality'}}
                                    rules={{ required: "Please select Speciality" }}
                                    valueGot={parseInt(patientData?.speciality_id)}
                                    setValue={setValue}
                                    selectOption={specialitiesList?.map((item,index)=>{
                                        return  {value:item.id,label:item.name}
                                    })}
                                    validate={errors?.patient?.speciality_id && "error"}
                                    validMessage={errors?.patient?.speciality_id && errors?.patient?.speciality_id?.message}
                                />
                            </Col>
                            <Col span={6} xs={24} sm={24}  md={8} xxl={6}>
                                <InputField
                                    isRequired={true}
                                    fieldname="patient.patient_type"
                                    label="patient Type"
                                    control={control}
                                    rules={{
                                        required: "Please enter patient type.",
                                    }}
                                    iProps={{ placeholder: "patient Type", maxLength:"50" }}
                                    initValue=""
                                    valueGot={patientData?.patient_type}
                                    setValue={setValue}
                                    validate={errors?.patient?.patient_type && "error"}
                                    validMessage={errors?.patient?.patient_type && errors?.patient?.patient_type.message}
                                />
                            </Col>
                            <Col span={6} xs={24} sm={24}  md={8} xxl={6}>
                                <InputField
                                    isRequired={true}
                                    fieldname="patient.business_name"
                                    label="Business Name"
                                    control={control}
                                    rules={{
                                        required: "Please enter Business Name",
                                    }}
                                    iProps={{ placeholder: "Business Name"}}
                                    initValue=""
                                    valueGot={patientData?.business_name}
                                    setValue={setValue}
                                    validate={errors?.patient?.business_name && "error"}
                                    validMessage={errors?.patient?.business_name && errors?.patient?.business_name.message}
                                />
                            </Col>
                            <Col span={6} xs={24} sm={24}  md={8} xxl={6}>
                                <InputField
                                    isRequired={true}
                                    fieldname="patient.bank_name"
                                    label="Bank Name"
                                    control={control}
                                    rules={{
                                        required: "Please enter Bank Name",
                                    }}
                                    iProps={{ placeholder: "Bank Name"}}
                                    initValue=""
                                    valueGot={patientData?.bank_name}
                                    setValue={setValue}
                                    validate={errors?.patient?.bank_name && "error"}
                                    validMessage={errors?.patient?.bank_name && errors?.patient?.bank_name.message}

                                />
                            </Col>
                            <Col span={6} xs={24} sm={24}  md={8} xxl={6}>
                                <InputField
                                    isRequired={true}
                                    fieldname="patient.account_number"
                                    label="Account Number"
                                    control={control}
                                    rules={{
                                        required: "Please enter Account Number",
                                        minLength: { value: 12, message: "Please enter valid account number, 12 digits required" },
                                        maxLength: { value: 12, message: "Please enter valid account number, 12 digits required" },
                                    }}
                                    iProps={{ placeholder: "Account Number"}}
                                    initValue=""
                                    valueGot={patientData?.account_number}
                                    setValue={setValue}
                                    validate={errors?.patient?.account_number && "error"}
                                    validMessage={errors?.patient?.account_number && errors?.patient?.account_number.message}

                                />
                            </Col>
                            <Col span={6} xs={24} sm={24}  md={8} xxl={6}>
                                <InputField
                                    isRequired={true}
                                    fieldname="patient.routing_number"
                                    label="Routing Number"
                                    control={control}
                                    rules={{
                                        required: "Please enter Routing Number",
                                        minLength: { value: 9, message: "Please enter valid routing number, 9 digits required" },
                                        maxLength: { value: 9, message: "Please enter valid routing number, 9 digits required" },
                                    }}
                                    iProps={{ placeholder: "Routing Number"}}
                                    initValue=""
                                    valueGot={patientData?.routing_number}
                                    setValue={setValue}
                                    validate={errors?.patient?.routing_number && "error"}
                                    validMessage={errors?.patient?.routing_number && errors?.patient?.routing_number.message}
                                />
                            </Col> */}

                                <Col span={24} xs={24} sm={24} md={24} xxl={24}>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24} className="mt-1">
                        {(window.location.hostname == 'localhost' || is_login == 'true') && <SosGrid
                            loading={loading}
                            columns={[
                                {
                                    title: 'Ip Address',
                                    dataIndex: 'ip'
                                },
                                {
                                    title: 'Country',
                                    dataIndex: 'country'
                                },
                                {
                                    title: 'Region',
                                    dataIndex: 'region'
                                },
                                {
                                    title: 'City',
                                    dataIndex: 'city'
                                },
                                {
                                    title: 'Browser',
                                    dataIndex: 'browser'
                                },
                                {
                                    title: 'Device Type',
                                    dataIndex: 'device_type'
                                },
                                {
                                    title: 'Device',
                                    dataIndex: 'device'
                                },
                                {
                                    title: 'Platform',
                                    dataIndex: 'platform'
                                },
                                {
                                    title: 'Current',
                                    dataIndex: 'is_current',
                                    render: (text, record) => {
                                        return <AppointmentStatusTag status={record.is_current ? 'online' : 'offline'} />
                                    }
                                },
                                {
                                    title: 'Created At',
                                    dataIndex: 'created_at',
                                    render: (text, record) => {
                                        return moment.utc(record.created_at).format('llll'); // just for decoration
                                    }
                                }
                            ]}
                            dataSource={patientData?.user?.logins}
                        />}
                    </Col>
                </Row>
            </Form>
        </Spin>
    </DashboardLayout>
}

export default PatientEdit;