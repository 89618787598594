import { combineReducers } from "redux";
import header from "../../app/molecules/Header/duck/reducer";
import providers from '../../app/modules/Providers/ducks/reducers'
import specialities from '../../app/modules/Specialties/ducks/reducers'
import appointments from '../../app/modules/Appointments/ducks/reducers'
import patients from '../../app/modules/Patients/ducks/reducers'
import symptoms from '../../app/modules/Symptoms/ducks/reducers'
import dashboard from '../../app/modules/Dashboard/duck/reducer';
import states from '../../app/modules/States/ducks/reducers';
import cities from '../../app/modules/Cities/ducks/reducers';
import countries from '../../app/modules/Countries/ducks/reducers';
import transactions from '../../app/modules/Transactions/ducks/reducers';
import totalEarnings from '../../app/modules/TotalEarnings/duck/reducer';
import settings from '../../app/modules/Setting/ducks/reducers';
import notifications from '../../app/modules/NotificationViewer/ducks/reducers';
import staff from '../../app/modules/Staff/ducks/reducers';
import influencer from '../../app/modules/Influencer/ducks/reducers';

const appReducer = combineReducers({
  header,
  providers,
  specialities,
  appointments,
  patients,
  symptoms,
  dashboard,
  states,
  cities,
  transactions,
  totalEarnings,
  settings,
  notifications,
  countries,
  staff,
  influencer
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'LOGOUT_SUCCESS') state = undefined;

  return appReducer(state, action);
};

export default rootReducer;

