import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated,getCurrentUser} from '../utils/helper';



const  PublicRoute = (props) =>{
    const { component: Component, restricted = false, ...rest } = props;

    const render = props => {
        if (isAuthenticated && restricted) {
            return <Redirect to='/dashboard' />;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render} />;
}

export default PublicRoute;