import { Badge, message, Card, Col, Avatar, Row, Tooltip, Button, Select, Drawer } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../molecules/DashboardLayout';
import PageHeaderSection from '../../molecules/PageHeaderSection';
import SosGrid from '../../molecules/SosGrid';
import { appointmentsDelete, getAppointmentsList } from './ducks/actions'
import { EyeOutlined } from '@ant-design/icons';
import AppointmentStatusTag from '../../atoms/AppointmentStatusTag';
import { getProvidersList } from '../Providers/ducks/actions';
import { getPatientsList } from '../Patients/ducks/actions';
import { useHistory } from "react-router-dom";
import { resetState } from '../Specialties/ducks/actions';
import { createBrowserHistory } from "history";
import { Link, useLocation } from "react-router-dom";
import axios from '../../../utils/axiosInceptor';


const setFirstDropDownOption = [
  {
    value: "expired",
    label: "Expired"
  },
  {
    value: "scheduled",
    label: "Scheduled"
  },
  {
    value: "declined",
    label: "Declined"
  },
  // {
  //   value:"pending_approval",
  //   label:"Pending Approval"
  // },
  // {
  //   value:"pending_approval_expired",
  //   label:"Pending Approval Expired"
  // },
  {
    value: "complete_request",
    label: "Complete Request"
  },
  // {
  //   value:"reject",
  //   label:"Reject"
  // },
  {
    value: "cancel",
    label: "Cancel"
  },
  {
    value: "completed",
    label: "Completed"
  }
]

const columns = [
  {
    title: 'Patient Name',
    dataIndex: 'patient_name',
    key: 'patient_name',
    render: (text, record) => {
      return record?.patient?.full_name; // just for decoration
    },
  },
  {
    title: 'Provider Name',
    dataIndex: 'provider_name',
    render: (text, record) => {
      return record?.provider?.full_name; // just for decoration
    },
  },
  {
    title: 'Appointment Time',
    dataIndex: 'provider_time',

    render: (text, record) => {
      if (record?.provider_time)
        return moment(record?.provider_time).format('llll'); // just for decoration
      return null;
    },
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',

    render: (text, record) => {
      if (record?.created_at)
        return moment(record?.created_at).format('llll'); // just for decoration
      return null;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text, record) => {
      return <AppointmentStatusTag status={record?.status} />; // just for decoration
    },
  },
  {
    title: 'Transaction Status',
    dataIndex: 'transaction_status',
    render: (text, record) => {
      return <AppointmentStatusTag status={record?.transaction?.escrowStatus} />; // just for decoration
    },
  },
  // {
  //   title: 'Created at',
  //   dataIndex: 'created_at',
  //   key: 'created_at',
  //   render: (text, record) => {
  //     return moment(record.created_at).format('llll'); // just for decoration
  //   }
  // },
  {
    title: 'Actions',
    dataIndex: 'view',
    key: 'view',
    render: (text, record) => {
      return <Tooltip placement="top" title={'View'}><Link to={`/appointment/view/${record?.id}/${record?.uuid}`} ><EyeOutlined /></Link></Tooltip>
    }
  },
];

const Appointments = props => {

  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [onDeleteSuccess, setOnDeleteSuccess] = useState(false)
  const history = useHistory();

  const [isLocaleDelete, setIsLocaleDelete] = useState(query.get("isDelete"));

  const [params, setParams] = useState({
    field: 'created_at',
    order: 'DESC',
    pageSize: 10,
    provider_id: history?.location?.state?.provider_id,
    patient_id: history?.location?.state?.patient_id,
    staff_id: history?.location?.state?.staff_id,
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

  const appointemensList = useSelector(
    (state: any) => state.appointments.appointementList,
  );

  const appointemensDataDelete = useSelector(
    (state: any) => state.appointments.appointmentDelete,
  );

  const providersList = useSelector(
    (state: any) => state.providers.providerList,
  );

  const patientsList = useSelector(
    (state: any) => state.patients.patientList,
  );


  useEffect(() => {
    let url = "provider/list";
    const data = axios.get(url).then(() => { }).catch((e) => {
      if (e.response.data.message == "Unauthenticated") {
        localStorage.removeItem("sos_admin");
        message.error("Your token has been expired",5);
        history.push("/login");
      }
    });
  }, []);

  useEffect(() => {
    setRows([]);
    if (history?.location?.state?.provider_id) {
      setLoading(true)
    }
  }, [history?.location?.state?.provider_id])

  useEffect(() => {
    setRows([]);
    if (history?.location?.state?.patient_id) {
      setLoading(true)
    }
  }, [history?.location?.state?.patient_id])


  useEffect(() => {
    setRows([]);
    if (history?.location?.state?.staff_id) {
      setLoading(true)
    }
  }, [history?.location?.state?.staff_id])


  useEffect(() => {
    setLoading(true)
    dispatch(getAppointmentsList({
      pageSize: pagination?.pageSize,
      provider_id: history?.location?.state?.provider_id,
      patient_id: history?.location?.state?.patient_id,
      staff_id: history?.location?.state?.staff_id,
    }));
    dispatch(getProvidersList({
      pageSize: 100000,
    }));
    dispatch(getPatientsList({
      pageSize: 100000
    }));
  }, []);

  useEffect(() => {
    setLoading(true)
    dispatch(getAppointmentsList(params))
  }, [params])

  useEffect(() => {
    if (appointemensList?.data) {
      setPagination({
        ...pagination, ...{
          current: appointemensList?.current_page,
          total: appointemensList?.total,
          pageSize: appointemensList?.per_page
        }
      })
      setRows(appointemensList?.data)
      setLoading(false)
    }
  }, [appointemensList])

  const onChangeGrid = (value, filters, sorter) => {
    let param = {
      page: value.current,
      pageSize: value.pageSize,
      order: sorter.order,
      field: sorter.field,
    }
    setParams({ ...params, ...param })
  };

  const onSearch = value => {
    let param = {
      search: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeFirstDropDown = value => {
    let param = {
      status: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangeDateRange = value => {
    let param = {
      page: 1,
      startDate: value?.[0].utc().format('YYYY-MM-DD'),
      endDate: value?.[1].utc().format('YYYY-MM-DD'),
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onDelete = (value) => {
    let param = {
      ids: value
    }
    setLoading(true)
    setOnDeleteSuccess(false)
    dispatch(appointmentsDelete(param))
    setOnDeleteSuccess(true)
  }

  const onChangeProvider = value => {
    let param = {
      provider_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const onChangePatient = value => {
    let param = {
      patient_id: value,
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams({ ...params, ...param })
  }

  const MoreFilders = props => {
    const providersListOptions = [];
    providersList?.data?.map(function (item) {
      providersListOptions.push({
        label: item?.user?.full_name,
        value: item?.user?.id,
      });
    })

    const patientsListOptions = [];
    patientsList?.data?.map(function (item) {
      patientsListOptions.push({
        label: item?.user?.full_name,
        value: item?.user?.id,
      });
    })

    return <>
      <Col span={4} sm={6}>
        <Select
          style={{ width: '100%' }}
          placeholder={'Select Patient'}
          allowClear
          value={params?.patient_id}
          options={patientsListOptions}
          onChange={onChangePatient}
        />
      </Col>
      <Col span={4} sm={6}>
        <Select
          style={{ width: '100%' }}
          placeholder={'Select Provider'}
          allowClear
          value={params?.provider_id}
          options={providersListOptions}
          onChange={onChangeProvider}
        />
      </Col>
    </>
  }

  useEffect(() => {
    if (appointemensDataDelete?.data?.data) {
      dispatch(getAppointmentsList({
        pageSize: pagination?.pageSize
      }));
      message.success(appointemensDataDelete?.message);
    } else if (appointemensDataDelete?.message) {
      message.error(appointemensDataDelete?.message);
    }

    setLoading(false)
    dispatch(resetState({
      type: 'APPOINTMENT_DELETE',
      data: null
    }))

  }, [appointemensDataDelete])



  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'appointments',
      breadcrumbName: 'Appointments',
    }
  ];

  const tagRender = props => {
    return <AppointmentStatusTag tagRender={true} {...props} />
  }

  const resetFilter = () => {
    const history = createBrowserHistory();
    if (history.location.state && history.location.state.staff_id) {
      let state = { ...history.location.state };
      delete state.staff_id;
      history.replace({ ...history.location, state });
    }

    let param: any = {
      page: 1,
      pageSize: pagination?.pageSize
    }
    setParams(param)
  }

  return (
    <DashboardLayout>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} >
        <Col span={24}>
          <Card
            bordered={false}
            className="noicon-space"
          >
            <PageHeaderSection
              routes={routes}
              title={`Appointments | ${pagination?.total}`}
            />
          </Card>
        </Col>
        <Col span={24} className="mt-1">

          <Card
            bordered={false}
            className="noicon-space"
          >

            {history?.location?.state?.staff_id && <Button onClick={resetFilter}>
              Reset Filter
            </Button>
            }
            <SosGrid
              pagination={pagination}
              loading={loading}
              columns={columns}
              onChangeGrid={onChangeGrid}
              dataSource={rows}
              //onSearch={(value)=>onSearch(value)}
              handelDateRange={onChangeDateRange}
              onDelete={onDelete}
              setFirstDropDownPlaceHolder={"Select Appointment Status"}
              setFirstDropDownOption={setFirstDropDownOption}
              onChangeFirstDropDown={onChangeFirstDropDown}
              setFirstDropDownProps={{ mode: "multiple", tagRender: tagRender }}
              moreFilterProps={<MoreFilders />}
              onDeleteSuccess={onDeleteSuccess}
              rowKey={'id'}
              isBulkDelete={isLocaleDelete == 'delete_' ? true : false}
            />
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Appointments;
