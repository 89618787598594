import * as action_types from "./constants";

const providers = (state = {}, action) => {
  const { type, data } = action;
    switch (type) {
      case action_types.PROVIDERS_LIST: 
        return {
          ...state,
          providerList: data,
        };
      case action_types.PROVIDERS_DELETE: 
        return {
          ...state,
          providersDelete: data,
        };
      case action_types.PROVIDERS_EDIT: 
        return {
          ...state,
          providerEdit: data,
        };
      case action_types.PROVIDERS_UPDATE: 
        return {
          ...state,
          providerUpdate: data,
        };
      case action_types.PROVIDERS_STATUS_UPDATE: 
        return {
          ...state,
          providerStatusUpdate: data,
        };
      default:
        return state;
    }

};

export default providers;
